import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../../../helpers';
import ActionTypes from '../../../store/action-types';
import Layout from '../../../layouts/layout'
import DashboardLayout from '../../../layouts/dashboard'
import { toast } from 'react-toastify';
 import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router';

const Home = ({ user, onUserLogout, props }) => {
	const params= useParams()
	const [config, setConfig] = useState({});
	const [inputs, setInput] = useState([]);
	const logOut = () => {
		onUserLogout();
		history.push('/login');
	}
	
	const get = () => {
		http.get(`/products/${params.id}/edit`, {})
		.then(response => {
			if (response.data) {
				setConfig(response.data);
				setInput(response.data.data);
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}

	useEffect(() => {
		get();
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		const elm = e.target;
		const formData = new FormData(elm);
		http.post(`/products/${params.id}?_method=put`, formData).then(response => {
			if (response.data) {
				toast.success(response.data.message);
				history.push('/dashboard/products')
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}
	
  return (
    <Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100"} >
      <DashboardLayout>

		<form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="card border shadow-xs mb-4">
              <div className="card-header border-bottom pb-0">
                <div className="d-sm-flex align-items-center">
                  <div>
                    <h6 className="font-weight-semibold text-lg mb-0">{langs.updateProduct}</h6>
                    <p className="text-sm">{langs.updateProductNotic}</p>
                  </div>
                  <div className="ms-auto d-flex">
                    <label type="button" className="btn btn-sm btn-white me-2">
                      {langs.addImage}
					  <input type="file" name="media[]" className="type-file" multiple />
                    </label>
                  </div>
                </div>
              </div>
              <div className="card-body px-0 py-0">
                <div className="form-crul">
				
					<label>{langs.plate}</label>
					<div className="mb-3">
						<input type="text" name={`_id`} disabled={true} className="form-control " aria-label="_id" aria-describedby="_id-addon" required value={
							config?.data?._id
						}/>
					</div>
					
					{ config?.data?.media?.length > 0 ? <>
						<div className="in-product-images">
						{config?.data?.media.map((media, index) => (
							<p id={`m-${media.id}`}><img src={ media.url} /> <span onClick={()=>{
								http.post(`/products/${params.id}/edit/media`, { media_id : media.id }).then(response => {
									if (response.data) {
										toast.success(response.data.message);
										document.getElementById(`m-${media.id}`).remove()
									}
								})
								.catch(err => {
									toast.error(err.message);
								})
							}}>delete</span></p>

						))}
						</div>
					</> : <></>}
				
					{ config?.langs?.length > 0 ? 
						<>
						{config.langs.map((lang, index) => (
							<div className="row" key={index}>
								<div className="col-12" >
									<label>{langs.title} <h4 className="form-lang-title">{lang.title}</h4></label>
									<div className="mb-3">
										<input type="hidden" name={`information[title-${lang.id}][lang_id]`} value={lang.id} />
										<input type="hidden" name={`information[title-${lang.id}][type]`} value="title" />
										<input type="text" name={`information[title-${lang.id}][text]`} className="form-control " aria-label="title" aria-describedby="title-addon" required value={
											inputs?.content?.find( e => e.lang_id == lang.id && e.type == "title" )?.text
										} onChange={ (e) => {
											if(inputs?.content?.find( e => e.lang_id == lang.id && e.type == "title" )?.text)
												setInput(inputs.content.find( e => e.lang_id == lang.id && e.type == "title" ).text = e.target.value)
										} } />
									</div>
								</div>
								<div className="col-12" >
									<label>{langs.body} <h4 className="form-lang-title">{lang.title}</h4></label>
									<div className="mb-3">
										<input type="hidden" name={`information[body-${lang.id}][lang_id]`} value={lang.id} />
										<input type="hidden" name={`information[body-${lang.id}][type]`} value="body" />
										<textarea type="text" name={`information[body-${lang.id}][text]`} className="form-control " aria-label="body" aria-describedby="body-addon" required  >{
											config?.data?.content?.find( e => e.lang_id == lang.id && e.type == "body" )?.text 
										}</textarea>
									</div>
								</div>
							</div>
						))}
						</> : null
					}

					{ config?.prices?.length > 0 ? 
						<>
						<hr></hr>
						<div><h6 class="font-weight-semibold text-lg mb-0">{langs.price}</h6><p class="text-sm">{langs.priceNotic}</p></div>

						{config.prices.map((price, index) => (
							<div className="row" key={index}>
								<div className="col-12" >
									<label>{langs.price} <h4 className="form-lang-title">{langs[price]}</h4></label>
									<div className="mb-3">
										<input type="number" step="any" name={`price[${price}]`} className="form-control" aria-label="price" aria-describedby="title-addon" required value={
											config?.data?.price?.find( e => e.type == price )?.price 
										} 
										onChange={ (e) => {
											const p = config.data.price;
											p.find( e => e.type == price ).price = e.target.value;
											setConfig({
												...config, data : {
													price: p
												}
											})

										} }
										
										/>
									</div>
								</div>
							</div>
						))}
						</> : null
					}

					{ config?.statuses?.length > 0 ? 
						<>
							<hr></hr>
						<div><h6 class="font-weight-semibold text-lg mb-0">{langs.status}</h6><p class="text-sm">{langs.statusNotic}</p></div>
							
							<label>{langs.status}</label>
							<div className="mb-3">
								<select name="status_id" className="form-control " aria-label="status_id" aria-describedby="title-addon" required >
									{config.statuses.map((status, index) => (
										<option key={index} value={status.id} selected={
											config?.data?.status_id == status.id ? true : false
										} >{status.label}</option>
									))}
								</select>
							</div>
						</> : null
					}


					{ config?.statuses?.length > 0 ? 
						<>
							<hr></hr>
						<div><h6 class="font-weight-semibold text-lg mb-0">{langs.category}</h6><p class="text-sm">{langs.categoryNotic}</p></div>
							
							<label>{langs.category}</label>
							<div className="mb-3">
								<select name="categories[]" className="form-control " aria-label="categories" aria-describedby="title-addon" required >
									{config.categories.map((category, index) => (
										<option key={index} value={category.id} selected={
											config?.data?.category?.find( e => e.id == category.id ) ? true : false
										} >{category.title}</option>
									))}
								</select>
							</div>
						</> : null
					}
					
					
				 

                    <div className="text-center">
                      <button type="submit" className="btn btn-dark w-100 mt-4 mb-3">{langs.update}</button>

                    </div>
                  

              </div>
              </div>
            </div>
          </div>
        </div>
		</form>
      </DashboardLayout>
    </Layout>




  );
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import registerWithJWT from '../store/actions/auth/registerAction';

import imageSignUp from '../assets/img/image-sign-up.jpg'
import Layout from '../layouts/layout'
import { history } from '../helpers';

function SignUpPage({ onUserRegister, onUserLogin }) {
	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
			passwordRetype: ''
		},
		validationSchema: Yup.object({
			username: Yup.string('Enter your username')
				.required('username is required'),
			password: Yup.string('Enter your password')
				.min(6, 'Password should be of minimum 6 characters length')
				.max(30, 'Password should be of minimum 30 characters length')
				.required('Password is required'),
			passwordRetype: Yup.string('Retype password')
				.min(6, 'Password should be of minimum 6 characters length')
				.max(30, 'Password should be of minimum 30 characters length')
				.required('Password is required')
				.when('password', {
					is: (val) => !!(val && val.length > 0),
					then: Yup.string().oneOf(
						[Yup.ref('password')],
						'Passwords do not match'
					)
				}),
		}),
		onSubmit: (values, { resetForm }) => {
			onUserRegister(values, resetForm);
			history.push('/login')
		}
	});
	return (
		<Layout foo="bar" auth={true}>
			<section className="auth-form">
				<div className="page-header min-vh-100">
					<div className="container">
						<div className="row" dir="ltr">
							
							<div className="col-md-4 d-flex flex-column mx-auto">
								<div className="card card-plain">
									<div className="card-header pb-0 text-left bg-transparent">
										<h3 className="font-weight-black text-dark display-6">Sign up</h3>
										<p className="mb-0">Nice to meet you! Please enter your details.</p>
									</div>
									<div className="card-body">

										<form onSubmit={formik.handleSubmit}>
											<label>username Address
												<b className={Boolean(formik.errors.username) ? 'text-error' : ''}>{formik.errors.username}</b>
											</label>
											<div className="mb-3">
												<input
													variant="outlined"
													fullWidth
													id="username"
													label="username Address"
													name="username"
													value={formik.values.username}
													onChange={formik.handleChange}
													error={formik.touched.username && Boolean(formik.errors.username)}
													helperText={formik.touched.username && formik.errors.username}
													className={`form-control ${Boolean(formik.errors.username) ? 'is-invalid' : 'is-valid'} `}
												/>
											</div>
											<label>Password
												<b className={Boolean(formik.errors.password) ? 'text-error' : 'is-valid'}>{formik.errors.password}</b>
											</label>
											<div className="mb-3">
												<input
													variant="outlined"
													fullWidth
													name="password"
													label="Password"
													type="password"
													id="password"
													value={formik.values.password}
													onChange={formik.handleChange}
													error={formik.touched.password && Boolean(formik.errors.password)}
													helperText={formik.touched.password && formik.errors.password}
													className={`form-control ${Boolean(formik.errors.password) ? 'is-invalid' : 'is-valid'} `}
												/>
											</div>
											<label>Retype Password
												<b className={Boolean(formik.errors.passwordRetype) ? 'text-error' : ''}>{formik.errors.passwordRetype}</b>
											</label>
											<div className="mb-3">
												<input
													variant="outlined"
													fullWidth
													name="passwordRetype"
													label="Retype Password"
													type="password"
													id="passwordRetype"
													value={formik.values.passwordRetype}
													onChange={formik.handleChange}
													error={formik.touched.passwordRetype && Boolean(formik.errors.passwordRetype)}
													helperText={formik.touched.passwordRetype && formik.errors.passwordRetype}
													className={`form-control ${Boolean(formik.errors.passwordRetype) ? 'is-invalid' : 'is-valid'} `}
												/>
											</div>
											<div className="form-check form-check-info text-left mb-0">
												<input className="form-check-input" type="checkbox" value="allowExtraEmails" id="flexCheckDefault" />
												<label className="font-weight-normal text-dark mb-0" for="flexCheckDefault">
													I agree the <a href="javascript:;" className="text-dark font-weight-bold">Terms and Conditions</a>.
												</label>
											</div>
											<div className="text-center">
												<button type="submit" className="btn btn-dark w-100 mt-4 mb-3">Sign up</button>
											</div>
										</form>
									</div>
									<div className="card-footer text-center pt-0 px-lg-2 px-1">
										<p className="mb-4 text-xs mx-auto">
											Already have an account?
											<a href="/login" className="text-dark font-weight-bold"> Sign in </a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}


const mapDispatchToProps = (dispatch) => ({
	onUserRegister: (values, resetForm) => dispatch(registerWithJWT(values, resetForm))
});
export default connect(null, mapDispatchToProps)(SignUpPage);
import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../helpers';
import ActionTypes from '../store/action-types';
import Layout from '../layouts/layout'
import DashboardLayout from '../layouts/dashboard'
import { toast } from 'react-toastify';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css'
  
import { Pagination , Navigation } from 'swiper/modules';

 
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";



const Home = ({ user, onUserLogout }) => {
	
 	
	const [products, setProducts] = useState({ data: [] });
	const [orderModal, setOrderModal] = useState(null);
	const [days, setDays] = useState([]);
	const [reports, setReports] = useState(null);

	const logOut = () => {
		onUserLogout();
		history.push('/login');
	}
	
	const check = (price_id, product_id, product, type) => {
		var iid = "b-" + new Date().getTime();
		http.get(`/orders/times?id=${product_id}`, {}).then(response => {
			if (response.data) {
			  console.log(response.data.map((e) => new Date(e)))
				setDays(response.data.map((e) => new Date(e)));
				setOrderModal({price_id, product_id, product, type, iid })
			}
		  })
		  .catch(err => {
			toast.error(err.message);
		  })
	}	
	
	const buy = (price_id, product_id, details = "") => {
		http.post(`/orders`, {
			product_id: product_id,
			price_id, 
			details
			
		})
		  .then(response => {
			if (response.data) {
				//get();
				toast.success(langs.reserved_successfully)
								history.push('/orders');


			}
		  })
		  .catch(err => {
			  /*
			console.log(err.response.data.message);
			console.log(err.response.status);
			console.log(err.response.headers);
				*/
			if(err?.response?.data?.message){
				toast.error(err.response.data.message);
			} else {
				toast.error(err.message);
			}
			  
		  })
	}
	
	const get = () => {
		http.get("/products?type=active", {})
		  .then(response => {
			if (response.data) {
			  console.log(response.data)
			  setProducts(response.data);
			}
		  })
		  .catch(err => {
			toast.error(err.message);
		  })
	}
	
	const report = () => {
		http.get("/report", {})
		  .then(response => {
			if (response.data) {
			  console.log(response.data)
			  setReports(response.data);
			}
		  })
		  .catch(err => {
			toast.error(err.message);
		  })
	}
	
  useEffect(() => {
		get();
	  if(user?.role == "admin" || user?.role == "operator"){
		report();
	  }
  }, []);

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndtDate] = useState(null);
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;

		return [year, month, day].join('-');
	}
	
	return (
		<Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100 home-page"} >
			<DashboardLayout>

		{ orderModal ? 
				<>
				<div className="order-box">
					<div>
						<p className="title ">{orderModal.product.title}</p>
						<div>
							<div className="order-box-div">
								<label className="label">{langs.rsv_time}</label>
								<div className="custom-style component-wrapper mx-2 i-from" id={orderModal.iid}>
									    <DatePicker 
											excludeDates={days}
											onChange={(date) => setStartDate((date))} 
											placeholderText={langs.ch_time} 
											selected={startDate}
											minDate={new Date()}
											disabledKeyboardNavigation
											 
										/>
										{ //<jb-date-input format="YYYY-MM-DD" placeholder={langs.ch_time} input-type={localStorage.lang == "fa" ? "JALALI" : "GREGORIAN"} ></jb-date-input>
										}
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.end_time}</label>
								<div className="custom-style component-wrapper mx-2 i-to" id={orderModal.iid}>
									<DatePicker 
										excludeDates={days}
										onChange={(date) => { setEndtDate(date) }} 
										placeholderText={langs.ch_time} 
										selected={endDate}
										minDate={startDate}
											disabledKeyboardNavigation
											 
									/>
									{ //<jb-date-input format="YYYY-MM-DD" placeholder={langs.ch_time} input-type={localStorage.lang == "fa" ? "JALALI" : "GREGORIAN"} ></jb-date-input>
									}
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.dep_type}</label>
								<div>
									<input type="radio" className="radio" name="drone" value="huey" checked />
									<label className="radio-txt">{langs.cash}</label>
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.pick_type}</label>
								<div className="text-center">
									<select className="pickup_place">
										<option value="airport">{langs.airport}</option>
										<option value="hotel">{langs.hotel}</option>
										<option value="shop">{langs.shop}</option>
									</select>
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.addr_type}</label>
								<div className="text-center">
									<textarea className="details"></textarea>
								</div>
							</div>
							<div className="flex">
								<div className="w-half"><a href="#" className="close" onClick={() => { setOrderModal(null) }}>{langs.close}</a></div>
								<div className="w-half"><a href="#" className="send" onClick={() => {
									buy(
										orderModal.price_id,
										orderModal.product_id,
										{
											from: formatDate(startDate),
											to: formatDate(endDate),
											place: document.querySelector('.pickup_place').value,
											detail: document.querySelector('.details').value,
										}
									);
									setOrderModal(null)
									
								}}>{langs.send_request}</a></div>
							</div>
							
						</div>
					</div>
				</div>
			</>
			: null }
			
			
			
		{ reports && user?.role == "operator" ? <>
		<div className="row">
		
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/reports" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue}</p>
									<h4 className="mb-0 font-weight-bold">{Number(reports?.income[0].income) ? parseFloat(reports?.income[0].income).toFixed(3) : 0}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>

			{ /*
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/reports" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.month})</p>
									<h4 className="mb-0 font-weight-bold">{Number(reports?.month[0].income) ? parseFloat(reports?.month[0].income).toFixed(3) : 0}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/reports" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.week})</p>
									<h4 className="mb-0 font-weight-bold">{Number(reports?.week[0].income) ? parseFloat(reports?.week[0].income).toFixed(3) : 0}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/reports" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.day})</p>
									<h4 className="mb-0 font-weight-bold">{Number(reports?.day[0].income) ? parseFloat(reports?.day[0].income).toFixed(3) : 0}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			*/ 
			}

			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/orders" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.orders}</p>
									<h4 className="mb-0 font-weight-bold">{(reports?.orders)}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.addad}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/products" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path fillRule="evenodd" d="M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
								<path d="M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.products}</p>
									<h4 className="mb-0 font-weight-bold">{(reports?.products)}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.addad}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
		</div>
		
		</> : <></> }
		
		
		
		{ reports && user?.role == "admin" ? <>
		<div className="row">
		
		
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/reports" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue}</p>
									<h4 className="mb-0 font-weight-bold">{Number(reports?.income[0].income) ? parseFloat(reports?.income[0].income).toFixed(3) : 0}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>

			{
				/*
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/reports" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.month})</p>
									<h4 className="mb-0 font-weight-bold">{Number(reports?.month[0].income) ? parseFloat(reports?.month[0].income).toFixed(3) : 0}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/reports" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.week})</p>
									<h4 className="mb-0 font-weight-bold">{Number(reports?.week[0].income) ? parseFloat(reports?.week[0].income).toFixed(3) : 0}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/reports" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.day})</p>
									<h4 className="mb-0 font-weight-bold">{Number(reports?.day[0].income) ? parseFloat(reports?.day[0].income).toFixed(3) : 0}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			
			
			*/
			}
			
			
			
			
			
			
			
			
			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/orders" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.orders}</p>
									<h4 className="mb-0 font-weight-bold">{(reports?.orders)}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.addad}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/products" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path fillRule="evenodd" d="M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
								<path d="M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.products}</p>
									<h4 className="mb-0 font-weight-bold">{(reports?.products)}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.addad}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="/dashboard/users" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path fillRule="evenodd" d="M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
								<path d="M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.users}</p>
									<h4 className="mb-0 font-weight-bold">{(reports?.users)}</h4>
									<div class="d-flex align-items-center"><span class="text-sm ms-1">{langs.addad}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>

		</div>
		</> : <></> }

			
			{ user && user?.information ? <>
			
				<div className="row">
					<div className="col-md-12">
						<div className="d-md-flex align-items-center mb-3 mx-2">
							<div className="mb-md-0 mb-3 w-full">
								<h3 className="font-weight-bold mb-0 dear-profile"> {user?.information.name} </h3>
								<p className="mb-0">{langs.welcome}</p>
							</div>
							<div className="flex">
								<a href={"/profile"} className="btn btn-sm btn-white btn-icon d-flex align-items-center mb-0 ms-md-auto  ">
									<span className="btn-inner--icon">
										<span className={`p-1 ${user?.status.name == "active" ? "bg-success" : "bg-danger" } rounded-circle d-flex me-2`}>
											<span className="visually-hidden">New</span>
										</span>
									</span>
									<span className="btn-inner--text width-80px">{user?.status.label}</span>
								</a>

							</div>
						</div>
					</div>
				</div>
			</> : <></> }
				
				<hr className="my-0"></hr>
					<h3 className="title-list">{langs.populars} <a href="/products">{langs.see_all}</a></h3>
					<Swiper
						className={'margin-10 home-swipers swiper-cell'}
						loops={true}
						parallax={true}
						speed={400}
						grabCursor={true}
 						mousewheel={false}
						fadeEffect={{ crossFade: true }}
						
						dir={localStorage.theme}
						slidesPerView={2}
						spaceBetween={10}
						pagination={{
							dynamicBullets: true,
							clickable: true,
						}}
						navigation={false} 
 						onSlideChange={() => console.log('slide change')}
						onSwiper={(swiper) => console.log(swiper)}
						
						breakpoints={{
							0: {
							  slidesPerView: 2,
							},
							400:{
							  slidesPerView: 2,
							},
							639: {
							  slidesPerView: 3,
							},
							865:{
							  slidesPerView: 4
							},
							1000:{
							  slidesPerView: 4
							},
							1500:{
							  slidesPerView: 4
							},
							1700:{
							  slidesPerView: 5
							}
						}}

					>
					
				{products.data.map((product, index) => (
					<>
					{ product.media.length > 0 ? 
					<>
					<SwiperSlide key={index}>
							<a href={`/product/${product._id}`} className="js-carousel-cell">
								<div className="ag-shop-card_box-wrap p-0 mb-15px mt-15px">
									<div className="ag-shop-card_box">
										<div className="ag-shop-card_body">
										{ product.media.length > 0 ?
											<>
											<div className="js-card-bg ag-card-bg" style={{
												backgroundImage: `url(${product.media[0].url})`
											}}></div>
											</>
											: <></> 
										}
										</div>
										<div className="ag-shop-card_footer">
											{
											/*
											<a href="#" className="ag-shop-card-footer_link">
												<i className="fa-solid fa-plus"></i>
											</a>
											*/ 
											}
											<span className="ag-shop-card-footer_title">{product.title}</span> 
											<span className="ag-shop-card-footer_products">{product.category[0].title}</span>
											
											

										{product.price.map((price, index) => (
										<div className="row prices-box mx-0" key={index}>
											 
											<div className="col-6" >
												<p>{langs.order} {price.label}</p>

												<p>
												
													<strong>{ parseFloat(price.price).toFixed(3) } </strong>
													<small>{langs.symbol}</small> 
												</p>
											</div>
											<div className="col-6 text-end">
											<a href="#" 
											onClick={() => { 
												if(product.status.name == "publish")
													check(price.id, product.id, product, price.type) 
											}} 
											className={`btn ${
												product.status.name == "publish" ? "btn-dark" : "btn-danger"
											}
											`}>{
												product.status.name == "publish" ? <> {langs.reserve} </> : product.status.label
											}
											</a>
											</div>
										</div>
										))}
											
											
											
										</div>
									</div>
								</div>
							</a>
					</SwiperSlide>
					</> : <></> }
					</>
						
				))}
				</Swiper>
				
				<hr className=""></hr>

				<h3 className="title-list">{langs.newest} <a href="/products">{langs.see_all}</a></h3>
					<Swiper
						className={'margin-10 home-swipers swiper-cell'}
						loops={true}
						parallax={true}
						speed={400}
						grabCursor={true}
 						mousewheel={false}
						fadeEffect={{ crossFade: true }}
						
						dir={localStorage.theme}
						slidesPerView={2}
						spaceBetween={10}
						pagination={{
							dynamicBullets: true,
							clickable: true,
						}}
						navigation={false} 
 						onSlideChange={() => console.log('slide change')}
						onSwiper={(swiper) => console.log(swiper)}

						breakpoints={{
							0: {
							  slidesPerView: 2,
							},
							400:{
							  slidesPerView: 2,
							},
							639: {
							  slidesPerView: 3,
							},
							865:{
							  slidesPerView: 4
							},
							1000:{
							  slidesPerView: 4
							},
							1500:{
							  slidesPerView: 4
							},
							1700:{
							  slidesPerView: 5
							}
						}}
						
					>
					
				{products.data.map((product, index) => (
					<>
					{ product.media.length > 0 ? 
					<>
					<SwiperSlide key={index}>
							<a href={`/product/${product._id}`} className="js-carousel-cell">
								<div className="ag-shop-card_box-wrap p-0 mb-15px mt-15px">
									<div className="ag-shop-card_box">
										<div className="ag-shop-card_body">
										{ product.media.length > 0 ?
											<>
											<div className="js-card-bg ag-card-bg" style={{
												backgroundImage: `url(${product.media[0].url})`
											}}></div>
											</>
											: <></> 
										}
										</div>
										<div className="ag-shop-card_footer">
											{
											/*
											<a href="#" className="ag-shop-card-footer_link">
												<i className="fa-solid fa-plus"></i>
											</a>
											*/ 
											}
											<span className="ag-shop-card-footer_title">{product.title}</span> 
											<span className="ag-shop-card-footer_products">{product.category[0].title}</span>
											
											

										{product.price.map((price, index) => (
										<div className="row prices-box mx-0" key={index}>
											 
											<div className="col-6" >
												<p>{langs.order} {price.label}</p>

												<p>
												
													<strong>{ parseFloat(price.price).toFixed(3) } </strong>
													<small>{langs.symbol}</small> 
												</p>
											</div>
											<div className="col-6 text-end">
											<a href="#" 
											onClick={() => { 
												if(product.status.name == "publish")
													check(price.id, product.id, product, price.type) 
											}} 
											className={`btn ${
												product.status.name == "publish" ? "btn-dark" : "btn-danger"
											}
											`}>{
												product.status.name == "publish" ? <> {langs.reserve} </> : product.status.label
											}
											</a>
											</div>
										</div>
										))}
											
											
											
										</div>
									</div>
								</div>
							</a>
					</SwiperSlide>
					</> : <></> }
					</>
						
				))}
				</Swiper>
				
				 

			</DashboardLayout>

		</Layout>




	);
}

const mapStateToProps = (state) => ({
	user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
	onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../helpers';
import ActionTypes from '../store/action-types';
import Layout from '../layouts/layout'
import DashboardLayout from '../layouts/dashboard'
import { toast } from 'react-toastify';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css'
  
  
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Home = ({ user, onUserLogout }) => {
	
 	
	const [products, setProducts] = useState({ data: [] });
	const [orderModal, setOrderModal] = useState(null);
	const [config, setConfig] = useState({});
	const [days, setDays] = useState([]);

	const logOut = () => {
		onUserLogout();
		history.push('/login');
	}
	
	const run = () => {
		http.get("/products/create", {})
		.then(response => {
			if (response.data) {
				setConfig(response.data);
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}
	
	const check = (price_id, product_id, product, type) => {
		var iid = "b-" + new Date().getTime();
		http.get(`/orders/times?id=${product_id}`, {}).then(response => {
			if (response.data) {
			  console.log(response.data.map((e) => new Date(e)))
				setDays(response.data.map((e) => new Date(e)));
				setOrderModal({price_id, product_id, product, type, iid })
			}
		  })
		  .catch(err => {
			toast.error(err.message);
		  })
		
	}
	
	const buy = (price_id, product_id, details = "") => {
		http.post(`/orders`, {
			product_id: product_id,
			price_id,
			details
		})
		.then(response => {
			if (response.data) {
				//get();
				toast.success(langs.reserved_successfully)
				history.push('/orders');

			}
		})
		.catch(err => {
			if(err?.response?.data?.message){
				toast.error(err.response.data.message);
			} else {
				toast.error(err.message);
			}
			  
		})
	}
	
	const get = (search = "", category = "") => {
		setProducts({ data: [] });
		http.get(`/products?q=${search}&category=${category}&type=active`, {})
		  .then(response => {
			if (response.data) {
			  console.log(response.data)
			  setProducts(response.data);

			}
		  })
		  .catch(err => {
			toast.error(err.message);
		  })
	}
	
  useEffect(() => {
	get();
	run();

  }, []);

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndtDate] = useState(null);
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;

		return [year, month, day].join('-');
	}
	
	return (
		<Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100 home-page"} >
			<DashboardLayout>
			{ orderModal ? 
				<>
				<div className="order-box">
					<div>
						<p className="title ">{orderModal.product.title}</p>
						<div>
							<div className="order-box-div">
								<label className="label">{langs.rsv_time}</label>
								<div className="custom-style component-wrapper mx-2 i-from" id={orderModal.iid}>
									    <DatePicker 
											excludeDates={days}
											onChange={(date) => setStartDate((date))} 
											placeholderText={langs.ch_time} 
											selected={startDate}
											minDate={new Date()}
											disabledKeyboardNavigation
											 
										/>
										{ //<jb-date-input format="YYYY-MM-DD" placeholder={langs.ch_time} input-type={localStorage.lang == "fa" ? "JALALI" : "GREGORIAN"} ></jb-date-input>
										}									
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.end_time}</label>
								<div className="custom-style component-wrapper mx-2 i-to" id={orderModal.iid}>
									<DatePicker 
										excludeDates={days}
										onChange={(date) => { setEndtDate(date) }} 
										placeholderText={langs.ch_time} 
										selected={endDate}
										minDate={startDate}
											disabledKeyboardNavigation
											 
									/>
									{ //<jb-date-input format="YYYY-MM-DD" placeholder={langs.ch_time} input-type={localStorage.lang == "fa" ? "JALALI" : "GREGORIAN"} ></jb-date-input>
									}
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.dep_type}</label>
								<div>
									<input type="radio" className="radio" name="drone" value="huey" checked />
									<label className="radio-txt">{langs.cash}</label>
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.pick_type}</label>
								<div className="text-center">
									<select className="pickup_place">
										<option value="airport">{langs.airport}</option>
										<option value="hotel">{langs.hotel}</option>
										<option value="shop">{langs.shop}</option>
									</select>
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.addr_type}</label>
								<div className="text-center">
									<textarea className="details"></textarea>
								</div>
							</div>
							<div className="flex">
								<div className="w-half"><a href="#" className="close" onClick={() => { setOrderModal(null) }}>{langs.close}</a></div>
								<div className="w-half"><a href="#" className="send" onClick={() => {
									 
									buy(
										orderModal.price_id,
										orderModal.product_id,
										{
											from: formatDate(startDate),
											to: formatDate(endDate),
											place: document.querySelector('.pickup_place').value,
											detail: document.querySelector('.details').value,
										}
									);
									setOrderModal(null)

								}}>{langs.send_request}</a></div>
							</div>
							
						</div>
					</div>
				</div>
			</>
			: null }
				
				

				<div className="">
						<div className="search-box">
							<div className="search-input">
								<i className="fa fa-search"></i>
								<input type="search" 
								onChange={(e) => {
									setProducts({ data: [] });
									get(e.target.value);
								}}
								placeholder={langs.search}/>
							</div>
						</div>
						
						{ config?.categories?.length > 0 ? 
							<>

						<div className="filter-categories">
							<div className="filter-category filter-main active" onClick={(e) => { 
								document.querySelectorAll('.filter-category').forEach(function(params) {
									params.classList.remove('active')
								})
									document.querySelector(`.filter-main`).classList.add('active')
								get("", "") 
							}}>
								<p>ALL</p>
							</div>
							{config.categories.map((category, index) => (
								<div className={`filter-category filter-${category.id}`} onClick={(e) => { 
									document.querySelectorAll('.filter-category').forEach(function(params) {
										params.classList.remove('active')
									})
									document.querySelector(`.filter-${category.id}`).classList.add('active')
									get("", category.id) 
								}} key={index}>
									<p>{category.title}</p>
								</div>
							))}
						</div>

							
							</> : null
						}
						
						
					<div className="row all-products">
						
					
						{products.data.map((product, index) => (
						<div className="col-lg-3 col-md-4 col-12 p-one" key={index}>
							<a href={`/product/${product._id}`} className="js-carousel-cell">
								<div className="ag-shop-card_box-wrap p-0 mb-15px">
									<div className="ag-shop-card_box">
										<div className="ag-shop-card_body">
										{ product.media.length > 0 ?
											<>
											<div className="js-card-bg ag-card-bg" style={{
												backgroundImage: `url(${product.media[0].url})`
											}}></div>
											</>
											: <></> 
										}
										</div>
										<div className="ag-shop-card_footer">
											{
											/*
											<a href="#" className="ag-shop-card-footer_link">
												<i className="fa-solid fa-plus"></i>
											</a>
											*/ 
											}
											<span className="ag-shop-card-footer_title">{product.title}</span> 
											<span className="ag-shop-card-footer_products">{product.category[0].title}</span>
											
											
	
										{product.price.map((price, index) => (
										<div className="row prices-box mx-0" key={index}>
											<div className="col-12 mb-5px" >
												<p className="text-capitalize">{langs.order} {price.label}</p>
												<p>
												
													<strong>{ parseInt(price.price).toLocaleString() } </strong>
													<small>{langs.symbol}</small> 
												</p>
											</div>
											<div className="col-12 text-end">
											<a href="#" 
											onClick={() => { 
												if(product.status.name == "publish")
													check(price.id, product.id, product, price.type) 
											}} 
											className={`btn ${
												product.status.name == "publish" ? "btn-dark" : "btn-danger"
											}
											`}>{
												product.status.name == "publish" ? <> {langs.reserve} </> : product.status.label
											}
											</a>
											</div>
										</div>
										))}
											
											
											
										</div>
									</div>
								</div>
							</a>
						</div>
							))}
					</div>



				</div>

			</DashboardLayout>

		</Layout>




	);
}

const mapStateToProps = (state) => ({
	user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
	onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
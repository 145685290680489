import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../../../helpers';
import ActionTypes from '../../../store/action-types';
import Layout from '../../../layouts/layout'
import DashboardLayout from '../../../layouts/dashboard'
import { toast } from 'react-toastify';
 
const Home = ({ user, onUserLogout }) => {

  const [users, setUsers] = useState({ data: [] });
  const [option, setOptions] = useState({ status_id: "", from: "", to: "", user_id: "", page: 1, operator_id: 0, role : ""});

  const logOut = () => {
    onUserLogout();
    history.push('/login');
  }

  const get = () => {
	setUsers({ data: [] });	  
    http.get(`/users?role=${option.role}`, {})
      .then(response => {
        if (response.data) {
          console.log(response.data)
          setUsers(response.data);
        }
      })
      .catch(err => {
        toast.error(err.message);
      })
  }

  const deleteRecord = (id) => {
	const ok = window.confirm(langs.ays);
	if(ok){
		http.post(`/users/${id}?_method=delete`, {}).then(response => {
			if (response.data) {
				setUsers({...users , data: users.data.filter(e => e.id != id)});
				toast.success(response.data.message);

			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}
	
  }

  useEffect(() => {
    get();
  }, []);

  return (
    <Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100"} >
      <DashboardLayout>


        <div className="row">
          <div className="col-12">
            <div className="card border shadow-xs mb-4">
              <div className="card-header border-bottom pb-0">
                <div className="d-sm-flex align-items-center">
                  <div>
                    <h6 className="font-weight-semibold text-lg mb-0">{langs.users}</h6>
                    <p className="text-sm">{langs.createUsersNotic}</p>
                  </div>
                  <div className="ms-auto d-flex">
                    <a href='/dashboard/users/create' type="button" className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2">
                      <span className="btn-inner--icon">
                        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="d-block me-2">
                          <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                        </svg>
                      </span>
                      <span className="btn-inner--text">{langs.createUser}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body px-0 py-0">
			  
			  
	
					{ user.role == "admin" ?
						<>
							<div className="" style={{margin: "10px"}}>
								<select onChange={(e) => { 
									var json = option;
									json.role = (e.target.value) 
									json.page = 1;
									setOptions(json);
									get();
								}} className="form-control " style={{fontSize: "13px", fontWeight: 500 }} >
									<option value="">{langs.role}: {langs.allItems}</option>
									<option value={"customer"}>{langs.role}: {langs.customer}</option>
									<option value={"operator"}>{langs.role}: {langs.provider}</option>
								</select>
							</div>
						</> : null
					}
			  
			  
                <div className="table-responsive p-0">
                  <table className="table align-items-center mb-0">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">{langs.information}</th>
                        <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">{langs.name}</th>
                        <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">{langs.status}</th>
						  { user.role == "admin" ? <>

						
                        <th className="text-secondary opacity-7"></th>
						  </> : <></> }
                        <th className="text-secondary opacity-7"></th>

                      </tr>
                    </thead>
                    <tbody>
                      {users.data.map((user, index) => (

                        <tr key={index}>

                          <td>
                            <div className="  px-2 py-1">

							{user.information ? 
								<>
									<p className="text-sm text-dark font-weight-semibold mb-0">{user.information.nick_name}</p>
									<p className="text-sm text-dark font-weight-semibold mb-0">{user.information.name}</p>
									<p className="text-sm text-secondary mb-0">{user.information.id_card}</p>
									<a style={{margin : 0}}  href={`https://wa.me/${user.information.phone_number}`} target="_blank" type="button" className="btn btn-sm btn-dark btn-icon me-2">
										<span className="btn-inner--text">{langs.support}</span>
									</a>
									
								</>
							: null} 

                          </div>
                          </td>
                          <td>
                            <div className="d-flex px-2 py-1">
							{user?.media?.length > 0 ? 
								<>
								  <div className="d-flex align-items-center">
									<img src={user.media[0].url} className="avatar avatar-sm rounded-circle me-2" alt="user1" />
								  </div>
								</>
							: null} 
                              <div className="d-flex flex-column justify-content-center ms-1">
                                <h6 className="mb-0 text-sm font-weight-semibold">{user.username} 
								<span className={`badge badge-sm border border-success text-success bg-success hidden`}>{user?.role}</span>
								</h6>
                                <p className="text-sm text-secondary mb-0">{user.name}</p>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className={`badge badge-sm border border-success text-success bg-success`}>{user?.status?.label}</span>
                          </td>
                           <td className="align-middle">
                            <a href={`/dashboard/users/${user.id}/edit`} className="text-secondary font-weight-bold text-xs" data-bs-toggle="tooltip" data-bs-title="Edit user">
                              <svg width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.2201 2.02495C10.8292 1.63482 10.196 1.63545 9.80585 2.02636C9.41572 2.41727 9.41635 3.05044 9.80726 3.44057L11.2201 2.02495ZM12.5572 6.18502C12.9481 6.57516 13.5813 6.57453 13.9714 6.18362C14.3615 5.79271 14.3609 5.15954 13.97 4.7694L12.5572 6.18502ZM11.6803 1.56839L12.3867 2.2762L12.3867 2.27619L11.6803 1.56839ZM14.4302 4.31284L15.1367 5.02065L15.1367 5.02064L14.4302 4.31284ZM3.72198 15V16C3.98686 16 4.24091 15.8949 4.42839 15.7078L3.72198 15ZM0.999756 15H-0.000244141C-0.000244141 15.5523 0.447471 16 0.999756 16L0.999756 15ZM0.999756 12.2279L0.293346 11.5201C0.105383 11.7077 -0.000244141 11.9624 -0.000244141 12.2279H0.999756ZM9.80726 3.44057L12.5572 6.18502L13.97 4.7694L11.2201 2.02495L9.80726 3.44057ZM12.3867 2.27619C12.7557 1.90794 13.3549 1.90794 13.7238 2.27619L15.1367 0.860593C13.9869 -0.286864 12.1236 -0.286864 10.9739 0.860593L12.3867 2.27619ZM13.7238 2.27619C14.0917 2.64337 14.0917 3.23787 13.7238 3.60504L15.1367 5.02064C16.2875 3.8721 16.2875 2.00913 15.1367 0.860593L13.7238 2.27619ZM13.7238 3.60504L3.01557 14.2922L4.42839 15.7078L15.1367 5.02065L13.7238 3.60504ZM3.72198 14H0.999756V16H3.72198V14ZM1.99976 15V12.2279H-0.000244141V15H1.99976ZM1.70617 12.9357L12.3867 2.2762L10.9739 0.86059L0.293346 11.5201L1.70617 12.9357Z" fill="#64748B" />
                              </svg>
                            </a>
                          </td>
 						  { user.role == "admin" ? <>
                          <td>

                            <a href="#" onClick={() => { deleteRecord(user.id) }} className="text-secondary font-weight-bold text-xs" data-bs-toggle="tooltip" data-bs-title="Edit user">
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </a>
                          </td>
						  </> : <></> }

                        </tr>

                      ))}

                    </tbody>
                  </table>
                </div>

                <div className="border-top py-3 px-3 d-flex align-items-center">
                  <p className="font-weight-semibold mb-0 text-dark text-sm">{langs.page} {users.from} {langs.of} {users.total}</p>
                  <div className="ms-auto">
                    {users.next_page_url ? <button className="btn btn-sm btn-white mb-0">{langs.next_page}</button> : null}
                    {users.prev_page_url ? <button className="btn btn-sm btn-white mb-0">{langs.previous_page}</button> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </Layout>




  );
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../../../helpers';
import ActionTypes from '../../../store/action-types';
import Layout from '../../../layouts/layout'
import DashboardLayout from '../../../layouts/dashboard'
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


const Home = ({ user, onUserLogout }) => {
  const [mobile, setMobile] = useState(null)

	
	
	const [config, setConfig] = useState({});
	const logOut = () => {
		onUserLogout();
		history.push('/login');
	}
	
	const get = () => {
		http.get("/users/create", {})
		.then(response => {
			if (response.data) {
				setConfig(response.data);
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}

	useEffect(() => {
		get();
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		const elm = e.target;
		const formData = new FormData(elm);
		formData.append('phone_number', mobile);
		http.post("/users", formData).then(response => {
			if (response.data) {
				toast.success(response.data.message);
				history.push('/dashboard/users')
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}
	
  return (
    <Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100"} >
      <DashboardLayout>

		<form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="card border shadow-xs mb-4">
              <div className="card-header border-bottom pb-0">
                <div className="d-sm-flex align-items-center">
                  <div>
                    <h6 className="font-weight-semibold text-lg mb-0">{langs.createUser}</h6>
                    <p className="text-sm">{langs.createUserNotic}</p>
                  </div>

                </div>
              </div>
              <div className="card-body px-0 py-0">
                <div className="form-crul">
				
					<label>{langs.username}</label>
					<div className="mb-3">
						<input type="text" name={`username`} className="form-control " aria-label="username" aria-describedby="username-addon" required />
					</div>
					
					{
					/*
			
					<label>{langs.password}</label>
					<div className="mb-3">
						<input type="password" name={`password`} className="form-control " aria-label="password" aria-describedby="password-addon" required />
					</div>
					
					*/
					}

					{ config?.information?.length > 0 ? 
						<>
						{config.information.map((information, index) => (
							<div className="row" key={index}>
								<div className="col-12" >
									<label>{langs[information]}</label>
									<div className="mb-3">
										{ ["phone_number"].includes(information) ? <>
											<PhoneInput
												placeholder="Enter phone number"
												value={mobile}
												international
												withCountryCallingCode
												onChange={setMobile}/>
										</> : <>
											<input type={["id_card_path", "id_card_back_path"].includes(information) ? "file" : "text"} name={information} className="form-control" />
										</>}
									</div>
								</div>
							</div>
						))}
						</> : null
					}

					

					{ config?.statuses?.length > 0 ? 
						<>
							<hr></hr>
							<div><h6 class="font-weight-semibold text-lg mb-0">{langs.status}</h6><p class="text-sm">{langs.statusNotic}</p></div>
							
							<label>{langs.status}</label>
							<div className="mb-3">
								<select name="status_id" className="form-control " aria-label="status_id" aria-describedby="title-addon" required >
									{config.statuses.map((status, index) => (
										<option key={index} value={status.id}>{status.label}</option>
									))}
								</select>
							</div>
						</> : null
					}

					{ config?.roles?.length > 0 ? 
						<>
							<label>{langs.role}</label>
							<div className="mb-3">
								<select name="role" className="form-control " aria-label="role" aria-describedby="title-addon" required >
									{config.roles.map((role, index) => (
										<option key={index} value={role}>{langs[role]}</option>
									))}
								</select>
							</div>
						</> : null
					}
					
					{ config?.percents?.length > 0 ? 
						<>
						{config.percents.map((percents, index) => (
							<div className="row" key={index}>
								<div className="col-12" >
									<label>{langs[percents]} {langs.percent}</label>
									<div className="mb-3">
										<input type={"text"}  min={0} max={100} name={`${percents}_percent`} className="form-control" />
									</div>
								</div>
							</div>
						))}
						</> : null
					}



                    <div className="text-center">
                      <button type="submit" className="btn btn-dark w-100 mt-4 mb-3">{langs.create}</button>

                    </div>
                  

              </div>
              </div>
            </div>
          </div>
        </div>
		</form>
      </DashboardLayout>
    </Layout>




  );
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
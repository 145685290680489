import { useState, useEffect, React } from "react";
import Config from "../config/app";
import { connect } from 'react-redux';
import { history , langs } from '../helpers';
import ActionTypes from '../store/action-types';
import Aside from './aside';
import Navbar from './navbar';

import imageSignIn from '../assets/img/2.jpg'

const Layout = ({
	children, onUserLogout, auth, user, dashboard, beforeMain
}) => {

	const logOut = () => {
		onUserLogout();
		history.push('/login');
	}

	const [menuBar, setMenuBar] = useState("false");
	const [rtl, setRtl] = useState(localStorage.theme);

	return (
		<>
			<div dir={rtl} theme-lang={localStorage.lang} className={`theme-${rtl} ${rtl} ${dashboard ? "in-dashboard" : ""} ${auth ? "auth-pages" : ""}`}>

				<div className={"aside-backdrop"} onClick={(e) => {
					document.querySelector('aside').classList.remove("active-aside");
					document.querySelector('.aside-backdrop').classList.remove("block");
				}}></div>
				<div className={`container position-sticky z-index-sticky top-0`} >
					<div className="row navbar-row">
						<div className="col-12">
							<Navbar></Navbar>
						</div>
					</div>
				</div>
				<div className={beforeMain}>

					<main className={`main-content mt-0  ${dashboard ? "position-relative border-radius-lg" : ""} `} style={{ backgroundImage: auth ? null /* `url(${imageSignIn})` */ : null }}>
						{children}
					</main>
					{
						dashboard ?
							<aside className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 bg-slate-900 ${rtl == "ltr" ? "fixed-start" : "fixed-end rotate-caret"}`} id="sidenav-main"><Aside></Aside></aside>
							:
							null
					}

				</div>

			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	const {
		isAuthLoading,
	} = state.auth;
	return {
		isAuthLoading,
		user: state.auth.currentUser
	}
}

const mapDispatchToProps = (dispatch) => ({
	onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
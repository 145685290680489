import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../../../helpers';
import ActionTypes from '../../../store/action-types';
import Layout from '../../../layouts/layout'
import DashboardLayout from '../../../layouts/dashboard'
import { toast } from 'react-toastify';
 import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router';

const Home = ({ user, onUserLogout }) => {
	
	const params= useParams()
	const [config, setConfig] = useState({});
	const [inputs, setInput] = useState([]);
	const [addDetail, setAddDetail] = useState(false);
	const logOut = () => {
		onUserLogout();
		history.push('/login');
	}
	
	const get = () => {
		http.get(`/orders/${params.id}/edit`, {})
		.then(response => {
			if (response.data) {
				console.log(response.data.data)
				setInput(response.data.data);
				setConfig(response.data);
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}

	var toHHMMSS = (secs) => {
		var sec_num = parseInt(secs, 10)
		var hours   = Math.floor(sec_num / 3600)
		var minutes = Math.floor(sec_num / 60) % 60
		var seconds = sec_num % 60

		return [hours,minutes,seconds]
			.map(v => v < 10 ? "0" + v : v)
			.filter((v,i) => v !== "00" || i > 0)
			.join(":")
	}

	var fromTo = (to, from) => {
		var dateFuture = to;
		var dateNow = from;

		var seconds = Math.floor((dateFuture - (dateNow))/1000);
		var minutes = Math.floor(seconds/60);
		var hours = Math.floor(minutes/60);
		var days = Math.floor(hours/24);

		hours = hours-(days*24);
		minutes = minutes-(days*24*60)-(hours*60);
		seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
		
		
		let pm = "";
		if(days > 0) pm += `${days} ${langs.dayLabel},`;
		if(hours > 0) pm += `${hours} ${langs.hourLabel},`;
		if(minutes > 0) pm+= `${minutes} ${langs.minLabel},`;
		if(seconds > 0) pm+= `${seconds} ${langs.secLabel}`;
		return pm;
	}
	
	const calPrice = (to, from, price) => {
		var dateFuture = to;
		var dateNow = from;

		var seconds = Math.floor((dateFuture - (dateNow))/1000);
		var minutes = (seconds/60);
		var hours = (minutes/60);
		var days = (hours/24);
		
		return hours * price
		
	}
	
	useEffect(() => {
		get();
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		const elm = e.target;
		const formData = new FormData(elm);
		http.post(`/orders/${params.id}?_method=put`, formData).then(response => {
			if (response.data) {
				get();
				toast.success(langs.done_successfully);
				//history.push('/dashboard/orders')
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}
	
  return (
    <Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100"} >
      <DashboardLayout>

		<form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="card border shadow-xs mb-4">
              <div className="card-header border-bottom pb-0">
                <div className="d-sm-flex align-items-center">
                  <div>
                    <h6 className="font-weight-semibold text-lg mb-0">{langs.updateOrder}</h6>
                    <p className="text-sm">{langs.updateProductNotic}</p>
                  </div>
 
                </div>
              </div>
              <div className="card-body px-0 py-0">
                <div className="form-crul">
				
				
					<label>{langs.username}</label>
					<div className="mb-3">
						<input type="text" className="form-control " aria-label="username" aria-describedby="username-addon" required disabled={true} value={
							inputs?.user?.username
						}/>
					</div>
						
					<label>{langs.first_name}</label>
					<div className="mb-3">
						<input type="text" className="form-control" aria-label="username" aria-describedby="username-addon" required disabled={true} value={
							inputs?.user?.information.first_name
						}/>
					</div>
						
					<label>{langs.last_name}</label>
					<div className="mb-3">
						<input type="text" className="form-control" aria-label="username" aria-describedby="username-addon" required disabled={true} value={
							inputs?.user?.information.last_name
						}/>
					</div>
					
					<input type="hidden" name="product_id" value={inputs?.product_id} />
					<input type="hidden" name="price_id" value={inputs?.price_id} />
						
					<label>{langs.product}</label>
					<div className="mb-3">
						<input type="text" className="form-control" aria-label="username" aria-describedby="username-addon" required disabled={true} value={
							inputs?.product?.title
						}/>
					</div>
						
					
				
					<label>{langs.base_price}</label>
					<div className="mb-3">
						<input type="text" name="product_price" className="form-control" aria-label="username" aria-describedby="username-addon" required disabled={true} value={
							inputs?.product_price
						}
						onChange={ (e) => {
							setInput({
								...inputs, product_price: e.target.value
							})
						} }
						/>
					</div>
						
					<label>{langs.price}</label>
					<div className="mb-3">
						<input type="text" name="order_price" className="form-control" aria-label="username" aria-describedby="username-addon" required disabled={true} value={
							inputs?.order_price
							//config?.operator?.week_percent
						}
						onChange={ (e) => {
							setInput({
								...inputs, order_price: e.target.value
							})
						} }
						/>
					</div>
						
					
					<label>{langs.details}</label>
						{ inputs.details ? 
							<>
								<div className="mb-3">
									<p className="details-text" aria-label="username" aria-describedby="username-addon" required disabled={true} >{
							  inputs?.details.split('\n').map(function( item, idx) {
								return (
									<span key={idx}>
									  {item}
									  <br></br>
									</span>
								)
							  })
							}</p>
								</div>
								<hr></hr>
							</>
							: 
							<>
								<div className="form-check form-check-info text-left mb-0" >
								  <input className="form-check-input" type="checkbox" onChange={(e) => {
									  setAddDetail(e.target.checked)
								  }} />
								  <label className="font-weight-normal text-dark mb-0" >{langs.add_detail}</label>
								</div>
								{ addDetail ? 
									<>
										<div className="mb-3">
											<textarea name="details" type="text" className="form-control" aria-label="username" aria-describedby="username-addon" required ></textarea>
										</div>
										<hr></hr>
									</>
								: <></>
								}
							</>
						}					
								 
						
						
					
					{
							inputs?.status?.name == "pending" ?
								<>
								{ config?.statuses?.length > 0 ? 
									<>
									<div><h6 class="font-weight-semibold text-lg mb-0">{langs.status}</h6><p class="text-sm">{langs.statusNotic}</p></div>
										
										<label>{langs.status}</label>
										<div className="mb-3">
											<select name="status_id" className="form-control " aria-label="status_id" aria-describedby="title-addon" required >
												{config.statuses.map((status, index) => (
													<option key={index} value={status.id} selected={
														inputs?.status_id == status.id ? true : false
													} >{status.label}</option>
												))}
											</select>
										</div>
									</> : null
								}
									<div className="text-center">
										<button type="submit" className={`btn w-100 mt-4 mb-3 ${'btn-dark'}`}>{langs.update}</button>
									</div>
								</>
							: inputs?.status?.name == "accept" ?
								<>
								
									{ config?.statuses?.length > 0 ? 
										<>
										<div><h6 class="font-weight-semibold text-lg mb-0">{langs.status}</h6><p class="text-sm">{langs.statusNotic}</p></div>
											
											<label>{langs.status}</label>
											<div className="mb-3">
												<select name="status_id" className="form-control " aria-label="status_id" aria-describedby="title-addon" required >
													{config.statuses.map((status, index) => (
														<option key={index} value={status.id} selected={
															config?.statuses?.find(e => e.name == "finish").id == status.id ? true : false
														} >{status.label}</option>
													))}
												</select>
											</div>
										</> : null
									}
								
									<div className="text-center">
										<button type="submit" className={`btn w-100 mt-4 mb-3 ${'btn-danger'}`}>{langs.finish}</button>
									</div>
								</>
							: inputs?.status?.name == "payed" ?
								<>
									<p>{langs.finalPrice}: {
										
									parseInt(
										Math.round(
										calPrice(new Date(inputs?.finished_at), new Date(inputs?.accepted_at), inputs?.order_price)
										)).toLocaleString()
									} {langs.symbol}</p>
									
									
									<p>{langs.rentDuration}: { fromTo(new Date(inputs?.finished_at), new Date(inputs?.accepted_at)) } </p>
									
								</>
							: inputs?.status?.name == "finish" ?
								<>
									<p>{langs.finalPrice}: {
										
									parseInt(
										Math.round(
										calPrice(new Date(inputs?.finished_at), new Date(inputs?.accepted_at), inputs?.order_price)
										)).toLocaleString()
									} {langs.symbol}</p>
									
									<p>{langs.rentDuration}: { fromTo(new Date(inputs?.finished_at), new Date(inputs?.accepted_at)) } </p>
									
									{ 
									/*
									<input type="hidden" name="status_id" value={config?.statuses?.find(e => e.name == "payed")?.id} />
									<div className="text-center">
										<button type="submit" className={`btn w-100 mt-4 mb-3 ${'btn-danger'}`}>{langs.payed}</button>
									</div>
									*/
									}
									
								</>
							: <></>
					}

              </div>
              </div>
            </div>
          </div>
        </div>
		</form>
      </DashboardLayout>
    </Layout>




  );
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../helpers';
import ActionTypes from '../store/action-types';
import Layout from '../layouts/layout'
import DashboardLayout from '../layouts/dashboard'
import { toast } from 'react-toastify';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css'
  
import { Pagination , Navigation } from 'swiper/modules';

 
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";



const Home = ({ user, onUserLogout }) => {
	
 	
	const [products, setProducts] = useState({ data: [] });
	const [orderModal, setOrderModal] = useState(null);
	const [reports, setReports] = useState(null);
	const [days, setDays] = useState([]);

  useEffect(() => {

  }, []);

	return (
		<Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100 home-page"} >
			<DashboardLayout>
			
				<div className="row">
					<div className="col-md-12 about-sections">
						
						
						{ localStorage.lang == "en" ? <>

							<div>
								<span style={{ fontWeight: "bold" }}>*Privacy Policy for Rentino*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*1. Introduction*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								*1.1* Welcome to the privacy policy of Rentino, developed by Shahin Al-Sahra Trading LLC. We highly value your privacy and are committed to safeguarding your personal data. This privacy policy outlines how we protect your personal data as a customer and provides updates on your privacy rights and legal protection.
							</div>
							<div>
								<br/>
							</div>
							<div>
								*1.2* You are entitled to all the rights guaranteed by the 2016/679 EU regulations and relevant European and national laws. We will process your personal data strictly in compliance with these regulations.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*2. Information We Collect*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								We collect the following categories of information:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *Registration Information:* When you register for an account on the App, we collect your email address. We may also collect your name and other contact details if provided by you.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *User Documentation:* In order to facilitate car rental bookings, we require certain documentation from users, including but not limited to a copy of their passport or ID scan, and their driving license.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *Location Information:* We collect location information to facilitate the delivery of rental cars to users.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *Camera and Photos:* We may request access to your device's camera and photos to facilitate document uploads or other features within the App.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*3. How We Use Your Information*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								We use the information we collect for the following purposes:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- To facilitate the booking and rental of cars through our platform.
							</div>
							<div>
								- To communicate with users regarding their bookings and any updates related to our services.
							</div>
							<div>
								- To improve and optimize the functionality of the App.
							</div>
							<div>
								- To comply with legal obligations.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*4. How We Share Your Information*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								We may share your personal information with the following third parties:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- Car rental shops and their affiliates for the purpose of facilitating bookings and rentals.
							</div>
							<div>
								- Service providers who assist us in operating the App and providing our services.
							</div>
							<div>
								- Legal authorities if required by law or in response to legal process.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*5. Your Rights*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								You have the right to:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- Request access to your personal data.
							</div>
							<div>
								- Request correction of your personal data.
							</div>
							<div>
								- Request deletion of your personal data.
							</div>
							<div>
								- Object to the processing of your personal data.
							</div>
							<div>
								- Request the restriction of processing your personal data.
							</div>
							<div>
								- Request personal data transmission.
							</div>
							<div>
								- Withdraw your consent.
							</div>
							<div>
								<br/>
							</div>
							<div>
								If you wish to exercise any of these rights, please contact us at <a href="mailto:support@rentino.online">support@rentino.online</a>
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*6. Data Security*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								We take reasonable measures to protect the security of your personal information. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*7. Updates to this Privacy Policy*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*8. Contact Us*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@rentino.online">support@rentino.online</a>
							</div>
						</> : <></>}
						
						{ localStorage.lang == "fa" ? <>
						
							<div>
								<span style={{ fontWeight: "bold" }}>*سیاست حفظ حریم خصوصی برای Rentino*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*1. مقدمه*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								*1.1* به سیاست حفظ حریم خصوصی Rentino که توسط شرکت شاهین الصحرا للتجارة توسعه یافته است، خوش آمدید. ما ارزش بالایی برای حریم خصوصی شما قائل هستیم و متعهد به حفاظت از داده‌های شخصی شما هستیم. این سیاست حفظ حریم خصوصی نحوه حفاظت از داده‌های شخصی شما به عنوان مشتری را شرح می‌دهد و اطلاعات به‌روزی درباره حقوق حریم خصوصی شما و حفاظت قانونی ارائه می‌دهد.
							</div>
							<div>
								<br/>
							</div>
							<div>
								*1.2* شما از کلیه حقوق تضمین‌شده توسط مقررات اتحادیه اروپا 2016/679 و قوانین اروپایی و ملی مربوطه برخوردار هستید. ما داده‌های شخصی شما را به طور کامل مطابق با این مقررات پردازش خواهیم کرد.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*2. اطلاعاتی که جمع‌آوری می‌کنیم*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								ما دسته‌بندی‌های زیر از اطلاعات را جمع‌آوری می‌کنیم:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *اطلاعات ثبت‌نام:* زمانی که شما برای یک حساب در برنامه ثبت‌نام می‌کنید، آدرس ایمیل شما را جمع‌آوری می‌کنیم. همچنین ممکن است نام و سایر اطلاعات تماس شما را در صورت ارائه توسط شما جمع‌آوری کنیم.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *مدارک کاربران:* به منظور تسهیل رزرو خودرو، ما نیاز به برخی مدارک از کاربران داریم، از جمله اما نه محدود به، یک کپی از گذرنامه یا اسکن کارت شناسایی و گواهینامه رانندگی آن‌ها.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *اطلاعات مکانی:* ما اطلاعات مکانی را برای تسهیل تحویل خودروهای اجاره‌ای به کاربران جمع‌آوری می‌کنیم.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *دوربین و عکس‌ها:* ممکن است درخواست دسترسی به دوربین و عکس‌های دستگاه شما را برای تسهیل آپلود مدارک یا دیگر ویژگی‌های داخل برنامه کنیم.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*3. چگونه از اطلاعات شما استفاده می‌کنیم*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								ما از اطلاعات جمع‌آوری شده برای اهداف زیر استفاده می‌کنیم:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- برای تسهیل رزرو و اجاره خودرو از طریق پلتفرم ما.
							</div>
							<div>
								- برای ارتباط با کاربران در مورد رزروهایشان و هرگونه به‌روزرسانی مربوط به خدمات ما.
							</div>
							<div>
								- برای بهبود و بهینه‌سازی عملکرد برنامه.
							</div>
							<div>
								- برای رعایت الزامات قانونی.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*4. چگونه اطلاعات شما را به اشتراک می‌گذاریم*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								ما ممکن است اطلاعات شخصی شما را با اشخاص ثالث زیر به اشتراک بگذاریم:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- فروشگاه‌های اجاره خودرو و وابستگان آن‌ها به منظور تسهیل رزرو و اجاره.
							</div>
							<div>
								- ارائه‌دهندگان خدمات که به ما در اجرای برنامه و ارائه خدماتمان کمک می‌کنند.
							</div>
							<div>
								- مقامات قانونی در صورت لزوم توسط قانون یا در پاسخ به فرآیندهای قانونی.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*5. حقوق شما*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								شما حق دارید:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- درخواست دسترسی به داده‌های شخصی خود.
							</div>
							<div>
								- درخواست اصلاح داده‌های شخصی خود.
							</div>
							<div>
								- درخواست حذف داده‌های شخصی خود.
							</div>
							<div>
								- اعتراض به پردازش داده‌های شخصی خود.
							</div>
							<div>
								- درخواست محدودیت در پردازش داده‌های شخصی خود.
							</div>
							<div>
								- درخواست انتقال داده‌های شخصی.
							</div>
							<div>
								- بازپس‌گیری رضایت خود.
							</div>
							<div>
								<br/>
							</div>
							<div>
								اگر تمایل به استفاده از هر یک از این حقوق دارید، لطفاً با ما در تماس باشید: Support@Rentino.online
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*6. امنیت داده‌ها*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								ما اقدامات معقولی برای حفاظت از امنیت اطلاعات شخصی شما اتخاذ می‌کنیم. با این حال، هیچ روش انتقال از طریق اینترنت یا ذخیره‌سازی الکترونیکی به طور کامل امن نیست، بنابراین نمی‌توانیم امنیت مطلق را تضمین کنیم.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*7. به‌روزرسانی‌های این سیاست حفظ حریم خصوصی*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								ممکن است این سیاست حفظ حریم خصوصی را هر از گاهی به‌روزرسانی کنیم. ما شما را از هر گونه تغییر با قرار دادن سیاست حفظ حریم خصوصی جدید در این صفحه مطلع خواهیم کرد.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*8. تماس با ما*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								اگر سوال یا نگرانی در مورد این سیاست حفظ حریم خصوصی دارید، لطفاً با ما در تماس باشید: Support@Rentino.online
							</div>
							
						</> : <></>}
						
						{ localStorage.lang == "ar" ? <>
						
							<div>
								<span style={{ fontWeight: "bold" }}>*سياسة الخصوصية لـ رنتينو*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*1. المقدمة*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								*1.1* مرحبًا بك في سياسة الخصوصية لـ رنتينو، التي وضعتها شركة شاهين الصحراء للتجارة ذ.م.م. نحن نقدر بشدة خصوصيتك وملتزمون بحماية بياناتك الشخصية. توضح هذه السياسة كيف نحمي بياناتك الشخصية كعميل وتقدم تحديثات حول حقوق الخصوصية الخاصة بك والحماية القانونية.
							</div>
							<div>
								<br/>
							</div>
							<div>
								*1.2* لديك كل الحقوق المضمونة بموجب تشريعات الاتحاد الأوروبي 2016/679 والقوانين الأوروبية والوطنية ذات الصلة. سنقوم بمعالجة بياناتك الشخصية بصرامة وفقًا لهذه التشريعات.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*2. المعلومات التي نجمعها*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								نقوم بجمع الفئات التالية من المعلومات:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *معلومات التسجيل:* عند تسجيل حساب على التطبيق، نقوم بجمع عنوان بريدك الإلكتروني. قد نقوم أيضًا بجمع اسمك وتفاصيل الاتصال الأخرى إذا قدمتها.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *وثائق المستخدم:* من أجل تيسير حجوزات تأجير السيارات، نحتاج إلى بعض الوثائق من المستخدمين، بما في ذلك على سبيل المثال لا الحصر نسخة من جواز سفرهم أو مسح هويتهم، ورخصة القيادة.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *معلومات الموقع:* نجمع معلومات الموقع لتيسير توصيل السيارات المستأجرة للمستخدمين.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *الكاميرا والصور:* قد نطلب الوصول إلى كاميرا جهازك والصور لتيسير تحميل المستندات أو الميزات الأخرى داخل التطبيق.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*3. كيفية استخدام معلوماتك*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								نستخدم المعلومات التي نجمعها للأغراض التالية:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- لتيسير حجز وتأجير السيارات من خلال منصتنا.
							</div>
							<div>
								- للتواصل مع المستخدمين بشأن حجوزاتهم وأي تحديثات تتعلق بخدماتنا.
							</div>
							<div>
								- لتحسين وتحسين وظائف التطبيق.
							</div>
							<div>
								- للامتثال للالتزامات القانونية.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*4. كيف نشارك معلوماتك*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								قد نشارك معلوماتك الشخصية مع الأطراف الثالثة التالية:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- محلات تأجير السيارات وشركاتها التابعة لغرض تسهيل الحجوزات والتأجير.
							</div>
							<div>
								- مقدمي الخدمات الذين يساعدوننا في تشغيل التطبيق وتقديم خدماتنا.
							</div>
							<div>
								- السلطات القانونية إذا كانت مطلو
							</div>
							<div>
								<br/>
							</div>
							<div>
								بة بموجب القانون أو ردًا على عملية قانونية.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*5. حقوقك*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								لديك الحق في:
							</div>
							<div>
								<br/>
							</div>
							<div>
								- طلب الوصول إلى بياناتك الشخصية.
							</div>
							<div>
								- طلب تصحيح بياناتك الشخصية.
							</div>
							<div>
								- طلب حذف بياناتك الشخصية.
							</div>
							<div>
								- الاعتراض على معالجة بياناتك الشخصية.
							</div>
							<div>
								- طلب تقييد معالجة بياناتك الشخصية.
							</div>
							<div>
								- طلب نقل البيانات الشخصية.
							</div>
							<div>
								- سحب موافقتك.
							</div>
							<div>
								<br/>
							</div>
							<div>
								إذا كنت ترغب في ممارسة أي من هذه الحقوق، يرجى الاتصال بنا على البريد الإلكتروني Support@Rentino.online
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*6. أمان البيانات*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								نتخذ التدابير المعقولة لحماية أمان معلوماتك الشخصية. ومع ذلك، لا يمكن ضمان الأمان المطلق، لذا لا يمكننا ضمان الأمان التام.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*7. تحديثات على هذه السياسة الخصوصية*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								قد نقوم بتحديث هذه السياسة الخصوصية من وقت لآخر. سنقوم بإخطارك بأي تغييرات عن طريق نشر السياسة الخصوصية الجديدة على هذه الصفحة.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*8. اتصل بنا*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								إذا كان لديك أي أسئلة أو استفسارات حول هذه السياسة الخصوصية، يرجى الاتصال بنا على البريد الإلكتروني Support@Rentino.online
							</div>
						
						</> : <></>}
						
						
					</div>
				</div>
				

			</DashboardLayout>
		</Layout>

	);
}

const mapStateToProps = (state) => ({
	user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
	onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../helpers';
import ActionTypes from '../store/action-types';
import Layout from '../layouts/layout'
import DashboardLayout from '../layouts/dashboard'
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Thumbs, Navigation, FreeMode} from 'swiper/modules';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

 
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Home = ({ user, onUserLogout }) => {
	
	const params= useParams()	
	const [product, setProduct] = useState(null);
	const [orderModal, setOrderModal] = useState(null);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [days, setDays] = useState([]);

	const logOut = () => {
		onUserLogout();
		history.push('/login');
	}
	
	const get = () => {
		http.get(`/products/${params.id}/find`, {})
		  .then(response => {
			if (response.data) {
			  console.log(response.data)
			  setProduct(response.data);
			}
		  })
		  .catch(err => {
			toast.error(err.message);
		  })
	}
	
	const check = (price_id, product_id, product, type) => {
		var iid = "b-" + new Date().getTime();
		http.get(`/orders/times?id=${product_id}`, {}).then(response => {
			if (response.data) {
			  console.log(response.data.map((e) => new Date(e)))
				setDays(response.data.map((e) => new Date(e)));
				setOrderModal({price_id, product_id, product, type, iid })
			}
		  })
		  .catch(err => {
			toast.error(err.message);
		  })
	}	
	
	const buy = (price_id, product_id, details = "") => {
		http.post(`/orders`, {
			product_id: product_id,
			price_id,
			details
			
		})
		  .then(response => {
			if (response.data) {
				//get();
				toast.success(langs.reserved_successfully)
				history.push('/orders');


			}
		  })
		  .catch(err => {
			if(err?.response?.data?.message){
				toast.error(err.response.data.message);
			} else {
				toast.error(err.message);
			}
			  
		  })
	}
	
  useEffect(() => {
    get();
  }, []);

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndtDate] = useState(null);
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;

		return [year, month, day].join('-');
	}
	
	return (
		<Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100"} >
			<DashboardLayout>


		{ orderModal ? 
				<>
				<div className="order-box">
					<div>
						<p className="title ">{orderModal.product.title}</p>
						<div>
							<div className="order-box-div">
								<label className="label">{langs.rsv_time}</label>
								<div className="custom-style component-wrapper mx-2 i-from" id={orderModal.iid}>
									    <DatePicker 
											excludeDates={days}
											onChange={(date) => setStartDate((date))} 
											placeholderText={langs.ch_time} 
											selected={startDate}
											minDate={new Date()}
											disabledKeyboardNavigation
											 
										/>
										{ //<jb-date-input format="YYYY-MM-DD" placeholder={langs.ch_time} input-type={localStorage.lang == "fa" ? "JALALI" : "GREGORIAN"} ></jb-date-input>
										}
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.end_time}</label>
								<div className="custom-style component-wrapper mx-2 i-to" id={orderModal.iid}>
									<DatePicker 
										excludeDates={days}
										onChange={(date) => { setEndtDate(date) }} 
										placeholderText={langs.ch_time} 
										selected={endDate}
										minDate={startDate}
											disabledKeyboardNavigation
											 
									/>
									{ //<jb-date-input format="YYYY-MM-DD" placeholder={langs.ch_time} input-type={localStorage.lang == "fa" ? "JALALI" : "GREGORIAN"} ></jb-date-input>
									}
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.dep_type}</label>
								<div>
									<input type="radio" className="radio" name="drone" value="huey" checked />
									<label className="radio-txt">{langs.cash}</label>
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.pick_type}</label>
								<div className="text-center">
									<select className="pickup_place">
										<option value="airport">{langs.airport}</option>
										<option value="hotel">{langs.hotel}</option>
										<option value="shop">{langs.shop}</option>
									</select>
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.addr_type}</label>
								<div className="text-center">
									<textarea className="details"></textarea>
								</div>
							</div>
							<div className="flex">
								<div className="w-half"><a href="#" className="close" onClick={() => { setOrderModal(null) }}>{langs.close}</a></div>
								<div className="w-half"><a href="#" className="send" onClick={() => {
									
									buy(
										orderModal.price_id,
										orderModal.product_id,
										{
											from: formatDate(startDate),
											to: formatDate(endDate),
											place: document.querySelector('.pickup_place').value,
											detail: document.querySelector('.details').value,
										}
									);
									setOrderModal(null)
									
								}}>{langs.send_request}</a></div>
							</div>
							
						</div>
					</div>
				</div>
			</>
			: null }
			
			
			{product ?
				<>

				<div className="">
					

					<div className="row">
							<div className="col-sm-12 col-product-in-mobile" >
								<div className="card product-card">
		 
									<div className="card-body">
										<div className="row">
										<div className="col-sm-6">
										<Swiper
											className={' '}
											loop={true}
											parallax={true}
											speed={400}
											grabCursor={true}
											effect={"fade"}
											mousewheel={true}
											fadeEffect={{ crossFade: true }}
											
											dir={'rtl'}
											slidesPerView={1}
											spaceBetween={30}
											pagination={{
												clickable: true,
											}}
																				
											thumbs={{ swiper: thumbsSwiper }}
 
											
											onSlideChange={() => console.log('slide change')}
											onSwiper={(swiper) => console.log(swiper)}
											 
											
										>
											{product.media.map((media, index) => (
												<SwiperSlide key={index}>
													<img className="border-radius-lg swiper-image" src={media.url} />
												</SwiperSlide>
											))}

										</Swiper>
<Swiper
        onSwiper={setThumbsSwiper}
        slidesPerView={5}
		spaceBetween={10}

        freeMode={true}
        watchSlidesProgress={true}
         className="mySwiper"
		style={{ marginTop: "10px"}}
      >
											{product.media.map((media, index) => (
												<SwiperSlide key={index}>
													<img style={{
														width: "100%",
														height: "65px",
														border: "1px solid #1b1b1b20",
														padding: "0px",
														objectFit: "cover",
														borderRadius: "10px",
														backgroundColor: "#fff"
														
													}} src={media.url} />
												</SwiperSlide>
											))}

										</Swiper>

										<div className="in-desktop">
										{product.price.map((price, index) => (
										<div className="row prices-box" key={index}>
											<div className="col-6" >
												<p>{langs.order} {price.label}</p>
												<p>
												
													<strong>{ parseInt(price.price).toLocaleString() } </strong>
													<small>{langs.symbol}</small> 
												</p>
											</div>
											<div className="col-6 text-end">
											<span href="#" onClick={() => { 
												if(product.status.name == "publish")
													check(price.id, params.id, product, price.type)  
											}} className={`btn ${
												product.status.name == "publish" ? "btn-dark" : "btn-danger"
											}
											`}>{
												product.status.name == "publish" ? <> {langs.reserve} </> : product.status.label
											}

											</span>
											</div>
										</div>
										))}
										</div>
										
										
										
										</div>
										<div className="col-sm-6 col-prodocut-in-mobile">
										
									
										<h3 className="card-title mb-3">{product.title}</h3>
										<p className="card-text mb-4">
										
										{
										  product.body.split('\n').map(function( item, idx) {
											return (
												<span key={idx}>
												  {item}
												  <br></br>
												</span>
											)
										  })
										}
										</p>
										
										
										<div className="in-mobile">
										{product.price.map((price, index) => (
										<div className="row prices-box" key={index}>
											<div className={`${product.price.length == 1 ? "col-12 mb-10px" : "col-6"}`} >
												<p>{langs.order} {price.label}</p>
												<p>
												
													<strong>{ parseInt(price.price).toLocaleString() } </strong>
													<small>{langs.symbol}</small> 
												</p>
											</div>
											<div className={`${product.price.length == 1 ? "col-12 mb-10px" : "col-6"} text-end` }>
											<a style={{ width: "100%" }} href="#" onClick={() => { 
												if(product.status.name == "publish")
													check(price.id, params.id, product) 
											}} className={`btn ${
												product.status.name == "publish" ? "btn-dark" : "btn-danger"
											}
											`}>{
												product.status.name == "publish" ? <> {langs.reserve} </> : product.status.label
											}

											</a>
											</div>
										</div>
										))}
										</div>
										
										
										</div>
										
										</div>
									
									
									
									</div>
								</div>
							</div>
					</div>

				</div>

				</>
			: null }

			</DashboardLayout>

		</Layout>




	);
}

const mapStateToProps = (state) => ({
	user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
	onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
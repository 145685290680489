import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../helpers';
import ActionTypes from '../store/action-types';
import Layout from '../layouts/layout'
import DashboardLayout from '../layouts/dashboard'
import { toast } from 'react-toastify';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css'
  
import { Pagination , Navigation } from 'swiper/modules';

 
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";



const Home = ({ user, onUserLogout }) => {
	
 	
	const [products, setProducts] = useState({ data: [] });
	const [orderModal, setOrderModal] = useState(null);
	const [reports, setReports] = useState(null);
	const [days, setDays] = useState([]);

  useEffect(() => {

  }, []);

	return (
		<Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100 home-page"} >
			<DashboardLayout>
			
				<div className="row">
					<div className="col-md-12 about-sections">
						
						
						{ localStorage.lang == "en" ? <>

								<div>
									<span style={{ fontWeight: "bold" }}>*About Rentino*</span>
								</div>
								<div>
									<br/>
								</div>
								<div>
									Rentino is a revolutionary car rental platform developed by Shahin Al-Sahra Trading LLC, a forward-thinking company based in the vibrant Sultanate of Oman. Founded in 2022, Shahin Al-Sahra Trading LLC is driven by a passion for innovation and a commitment to enhancing the transportation landscape in Oman and beyond.
								</div>
								<div>
									<br/>
								</div>
								<div>
									<span style={{ fontWeight: "bold" }}>*Our Mission*</span>
								</div>
								<div>
									<br/>
								</div>
								<div>
									At Rentino, our mission is to redefine the car rental experience, making it simpler, more convenient, and accessible to all. We believe that renting a car should be a seamless process that empowers users to explore and travel with ease. Through cutting-edge technology and a customer-centric approach, we aim to set new standards in the car rental industry.
								</div>
								<div>
									<br/>
								</div>
								<div>
									<span style={{ fontWeight: "bold" }}>*Our Vision*</span>
								</div>
								<div>
									<br/>
								</div>
								<div>
									Our vision is to become the leading car rental platform in the region, known for our reliability, transparency, and dedication to customer satisfaction. We envision a future where renting a car is as effortless as tapping a button on your smartphone, and where travelers can embark on their journeys with confidence and peace of mind.
								</div>
								<div>
									<br/>
								</div>
								<div>
									<span style={{ fontWeight: "bold" }}>*Our Commitment*</span>
								</div>
								<div>
									<br/>
								</div>
								<div>
									At Shahin Al-Sahra Trading LLC, we are committed to excellence in everything we do. From the development of our platform to the delivery of our services, we strive for perfection and continuously seek ways to innovate and improve. We understand the importance of trust and reliability when it comes to renting a car, which is why we go above and beyond to ensure that every rental experience with Rentino is nothing short of exceptional.
								</div>
								<div>
									<br/>
								</div>
								<div>
									<span style={{ fontWeight: "bold" }}>*Why Choose Rentino?*</span>
								</div>
								<div>
									<br/>
								</div>
								<div>
									- *User-Friendly Platform:* Our intuitive platform is designed to make the car rental process as simple and straightforward as possible. With just a few taps, users can browse a wide selection of rental cars, compare prices, and book their ideal vehicle with ease.
								</div>
								<div>
									<br/>
								</div>
								<div>
									- *Trusted Rental Providers:* We partner with reputable car rental companies to offer our users a diverse range of high-quality vehicles to choose from. Each rental provider in our network is carefully vetted to ensure that they meet our strict standards of reliability and customer service.
								</div>
								<div>
									<br/>
								</div>
								<div>
									- *Transparent Pricing:* At Rentino, we believe in transparency. We provide upfront pricing with no hidden fees, so you can trust that the price you see is the price you'll pay. With Rentino, there are no surprises – just honest, straightforward pricing.
								</div>
								<div>
									<br/>
								</div>
								<div>
									- *24/7 Customer Support:* Our dedicated customer support team is available around the clock to assist you with any questions or concerns you may have. Whether you need help with your booking or assistance during your rental period, we're here to help.
								</div>
								<div>
									<br/>
								</div>
								<div>
									<span style={{ fontWeight: "bold" }}>*Join Us*</span>
								</div>
								<div>
									<br/>
								</div>
								<div>
									Join us on the journey to transform the way you rent a car. Experience the convenience, reliability, and peace of mind that Rentino has to offer. Wherever your journey takes you, Rentino is your trusted companion on the road.
								</div>						
						
						</> : <></>}
						
						{ localStorage.lang == "fa" ? <>
						

							<div>
								<span style={{ fontWeight: "bold" }}>*درباره Rentino*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								Rentino یک پلتفرم انقلابی اجاره خودرو است که توسط شرکت شاهین الصحرا تجارت LLC، یک شرکت پیشرو و مبتکر واقع در سلطنت عمان توسعه یافته است. شاهین الصحرا تجارت LLC که در سال 2022 تأسیس شده، با اشتیاق به نوآوری و تعهد به بهبود چشم‌انداز حمل و نقل در عمان و فراتر از آن فعالیت می‌کند.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*ماموریت ما*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								در Rentino، مأموریت ما بازتعریف تجربه اجاره خودرو است و آن را ساده‌تر، راحت‌تر و در دسترس‌تر برای همه می‌سازیم. ما معتقدیم که اجاره خودرو باید فرآیندی بی‌دردسر باشد که کاربران را قادر سازد با سهولت به سفر بپردازند. از طریق فناوری پیشرفته و رویکرد مشتری‌محور، ما هدف داریم استانداردهای جدیدی را در صنعت اجاره خودرو تعیین کنیم.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*چشم‌انداز ما*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								چشم‌انداز ما این است که به پیشروترین پلتفرم اجاره خودرو در منطقه تبدیل شویم که به خاطر اعتماد، شفافیت و تعهد به رضایت مشتری شناخته شود. ما آینده‌ای را می‌بینیم که در آن اجاره خودرو به آسانی فشردن یک دکمه بر روی تلفن هوشمند شما باشد و مسافران با اطمینان و آرامش خاطر به سفرهای خود بپردازند.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>*تعهد ما*</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								در شاهین الصحرا تجارت LLC، ما به برتری در هر کاری که انجام می‌دهیم متعهدیم. از توسعه پلتفرم ما تا ارائه خدماتمان، ما برای کمال تلاش می‌کنیم و به طور مداوم به دنبال راه‌های نوآورانه و بهبود هستیم. ما اهمیت اعتماد و قابلیت اطمینان در اجاره خودرو را درک می‌کنیم، به همین دلیل فراتر از انتظارات عمل می‌کنیم تا اطمینان حاصل کنیم که هر تجربه اجاره با Rentino فوق‌العاده است.
							</div>
							<div>
								<br/>
							</div>
							<div>
								*چرا Rentino را انتخاب کنید؟*
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *پلتفرم کاربرپسند:* پلتفرم ما به گونه‌ای طراحی شده است که فرآیند اجاره خودرو را تا حد ممکن ساده و راحت کند. با چند ضربه ساده، کاربران می‌توانند یک انتخاب گسترده از خودروهای اجاره‌ای را مرور کنند، قیمت‌ها را مقایسه کنند و به راحتی خودروی ایده‌آل خود را رزرو کنند.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *تأمین‌کنندگان اجاره معتبر:* ما با شرکت‌های معتبر اجاره خودرو همکاری می‌کنیم تا به کاربران خود تنوعی از خودروهای باکیفیت بالا ارائه دهیم. هر تأمین‌کننده اجاره در شبکه ما به دقت مورد بررسی قرار می‌گیرد تا اطمینان حاصل شود که آن‌ها استانداردهای سخت‌گیرانه ما را در زمینه قابلیت اطمینان و خدمات مشتری رعایت می‌کنند.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *قیمت‌گذاری شفاف:* در Rentino، ما به شفافیت اعتقاد داریم. ما قیمت‌های پیش‌پرداخته با هیچ هزینه پنهانی ارائه می‌دهیم، بنابراین می‌توانید مطمئن باشید که قیمتی که می‌بینید همان قیمتی است که پرداخت می‌کنید. با Rentino، هیچ سورپرایزی وجود ندارد – فقط قیمت‌گذاری صادقانه و شفاف.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *پشتیبانی 24/7 مشتری:* تیم پشتیبانی مشتری متعهد ما به صورت شبانه‌روزی آماده است تا به هر سوال یا نگرانی شما پاسخ دهد. چه در مورد رزرو خود نیاز به کمک داشته باشید و چه در دوره اجاره خود به کمک نیاز داشته باشید، ما اینجا هستیم تا کمک کنیم.
							</div>
							<div>
								<br/>
							</div>
							<div>
								*به ما بپیوندید*
							</div>
							<div>
								<br/>
							</div>
							<div>
								به ما بپیوندید تا نحوه اجاره خودرو را تغییر دهید. تجربه راحتی، قابلیت اطمینان و آرامش خاطری که Rentino ارائه می‌دهد را تجربه کنید. هر جا که سفرتان شما را می‌برد، Rentino همراه مطمئن شما در جاده است.
							</div>
						
						</> : <></>}
						
						{ localStorage.lang == "ar" ? <>
						

							<div>
								<span style={{ fontWeight: "bold" }}>حول رينتينو</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								رينتينو هي منصة تأجير سيارات ثورية تم تطويرها من قبل شركة شاهين الصحراء للتجارة ذ.م.م، شركة تفكر بمستقبل واعٍ مقرها في سلطنة عمان النابضة بالحياة. تأسست شركة شاهين الصحراء للتجارة في عام 2022، وتدفعها الشغف بالابتكار والالتزام بتحسين المشهد النقلي في عمان وخارجها.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>مهمتنا</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								في رينتينو، مهمتنا إعادة تعريف تجربة تأجير السيارات، جعلها أكثر بساطة وأسهل وأكثر وصولًا للجميع. نعتقد أن تأجير السيارات يجب أن يكون عملية سلسة تمكن المستخدمين من استكشاف والسفر بسهولة. من خلال التكنولوجيا المتطورة والنهج الموجه نحو العملاء، نهدف إلى تحديد معايير جديدة في صناعة تأجير السيارات.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>رؤيتنا</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								رؤيتنا هي أن نصبح الرائد في مجال تأجير السيارات في المنطقة، معروفين بموثوقيتنا وشفافيتنا والتفاني في رضا العملاء. نتصور مستقبلًا حيث يكون تأجير السيارات مثل الضغط على زر على هاتفك الذكي، وحيث يمكن للمسافرين الانطلاق في رحلاتهم بثقة وراحة البال.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>التزامنا</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								في شركة شاهين الصحراء للتجارة، نلتزم بالتميز في كل ما نقوم به. من تطوير منصتنا إلى تقديم خدماتنا، نسعى إلى الكمال ونبحث باستمرار عن طرق للابتكار والتحسين. نحن ندرك أهمية الثقة والموثوقية عندما يتعلق الأمر بتأجير السيارات، ولهذا السبب نتجاوز التوقعات لضمان أن كل تجربة تأجير مع رينتينو لا تقل عن استثنائية.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>لماذا اختيار رينتينو؟</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *منصة سهلة الاستخدام:* تم تصميم منصتنا البسيطة لجعل عملية تأجير السيارات أكثر بساطة ومباشرة قدر الإمكان. من خلال بضع نقرات فقط، يمكن للمستخدمين تصفح مجموعة واسعة من السيارات للإيجار، ومقارنة الأسعار، وحجز سيارتهم المثالية بسهولة.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *مقدمو تأجير موثوق بهم:* نتعاون مع شركات تأجير السيارات ذات السمعة الطيبة لنقدم لمستخدمينا مجموعة متنوعة من السيارات عالية الجودة للاختيار من بينها. يتم فحص كل مزود لتأجير السيارات في شبكتنا بعناية لضمان أنهم يفيون بمعاييرنا الصارمة للموثوقية وخدمة العملاء.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *تسعير شفاف:* في رينتينو، نؤمن بالشفافية. نقدم تسعيرًا مباشرًا دون رسوم خفية، حتى تتمكن من الثقة بأن السعر الذي تراه هو السعر الذي ستدفعه. مع رينتينو، لا توجد مفاجآت - فقط تسعير صادق ومباشر.
							</div>
							<div>
								<br/>
							</div>
							<div>
								- *دعم عملاء على مدار الساعة:* فريق دعم العملاء المخصص لدينا متاح على مدار الساعة لمساعدتك في أي أسئلة أو استفسارات قد تكون لديك. سواء كنت بحاجة إلى مساعدة في حجزك أو المساعدة أثناء فترة تأجيرك، نحن هنا للمساعدة.
							</div>
							<div>
								<br/>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>انضم إلينا</span>
							</div>
							<div>
								<br/>
							</div>
							<div>
								انضم إلينا في الرحلة لتحويل طريقة تأجير السيارات. اختبر الراحة والموثوقية وراحة البال التي يقدمها رينتينو. أينما كانت رحلتك تأخذك، رينتينو هو رفيقك الموثوق على الطريق.
							</div>						
						</> : <></>}
						
						
					</div>
				</div>
				

			</DashboardLayout>
		</Layout>

	);
}

const mapStateToProps = (state) => ({
	user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
	onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
import { useState, useEffect, React } from "react";
import Config from "../config/app";
import { connect } from 'react-redux';
import { history, langs } from '../helpers';
import ActionTypes from '../store/action-types';

const Navbar = ({
	children, onUserLogout, user,
}) => {
	const logOut = () => {
		onUserLogout();
		history.push('/login');
	}

	const [menuBar, setMenuBar] = useState("false");

	return (
		<>
			<nav className={`navbar main-navbar navbar-expand-lg blur border-radius-sm top-0 z-index-3 shadow position-absolute my-3 py-2 start-0 end-0 mx-4`}>
				<div className="container-fluid px-1">
					<button style={{ border: "0", paddingLeft: "5px", paddingRight: "5px" }} className="navbar-toggler shadow-none menu-bar-collapse" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded={menuBar} aria-label="Toggle navigation" onClick={(e) => {
						if (window.innerWidth < 1199.98) {
							document.querySelector('aside').classList.add("active-aside");
							document.querySelector('.aside-backdrop').classList.add("block");
						} else {
							setMenuBar(menuBar == "false" ? "true" : "false")
						}
					}}>
						<span className="navbar-toggler-icon mt-2">
							<span className="navbar-toggler-bar bar1"></span>
							<span className="navbar-toggler-bar bar2"></span>
							<span className="navbar-toggler-bar bar3"></span>
						</span>
					</button>
				
				
					
					<a className="navbar-brand font-weight-bolder ms-lg-0 " href="/">{Config.APP_NAME}</a>
					

					<div className="aside-toggle">
						<a href="/orders" ><i className="fa-solid fa-cart-shopping"></i></a>
					</div>
					
					
					<div className={`collapse navbar-collapse ${(menuBar == "false" ? "" : "show")}`} id="navigation">
						<ul className="navbar-nav mx-auto ms-xl-auto">
							<li className="nav-item">
								<a className="nav-link d-flex align-items-center me-2 " aria-current="page" href="/">
									<svg width="14" height="14" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="currentColor" className="opacity-6">
										<g id="dashboard" stroke="none" strokeWidth="1" fill-rule="evenodd">
											<g id="template" transform="translate(1.000000, 1.000000)">
												<path d="M0,1.71428571 C0,0.76752 0.76752,0 1.71428571,0 L22.2857143,0 C23.2325143,0 24,0.76752 24,1.71428571 L24,5.14285714 C24,6.08962286 23.2325143,6.85714286 22.2857143,6.85714286 L1.71428571,6.85714286 C0.76752,6.85714286 0,6.08962286 0,5.14285714 L0,1.71428571 Z"></path>
												<path d="M0,12 C0,11.0532171 0.76752,10.2857143 1.71428571,10.2857143 L12,10.2857143 C12.9468,10.2857143 13.7142857,11.0532171 13.7142857,12 L13.7142857,22.2857143 C13.7142857,23.2325143 12.9468,24 12,24 L1.71428571,24 C0.76752,24 0,23.2325143 0,22.2857143 L0,12 Z"></path>
												<path d="M18.8571429,10.2857143 C17.9103429,10.2857143 17.1428571,11.0532171 17.1428571,12 L17.1428571,22.2857143 C17.1428571,23.2325143 17.9103429,24 18.8571429,24 L22.2857143,24 C23.2325143,24 24,23.2325143 24,22.2857143 L24,12 C24,11.0532171 23.2325143,10.2857143 22.2857143,10.2857143 L18.8571429,10.2857143 Z"></path>
											</g>
										</g>
									</svg>
									{langs.dashboard}
								</a>
							</li>
							
							{user ?
								<>
									
									<li className="nav-item">
										<a className="nav-link d-flex align-items-center me-2 " href="/profile">
											<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="opacity-6">
												<path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clip-rule="evenodd" />
											</svg>
											{langs.profile}
										</a>
									</li>
									
									<li className="nav-item">
										<a className="nav-link d-flex align-items-center me-2 text-dark font-weight-bold" href="#" onClick={logOut}>
											<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className=" text-dark ">
												<path fill-rule="evenodd" d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z" clip-rule="evenodd" />
											</svg>
											{langs.logout}
										</a>
									</li>
									
								</>
								: 
								<>
									<li className="nav-item">
										<a className="nav-link d-flex align-items-center me-2 " href="/login">
											<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="opacity-6 ">
												<path fill-rule="evenodd" d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z" clip-rule="evenodd" />
											</svg>
											{langs.login}
										</a>
									</li>
								</>
							}
						</ul>
						<ul className="navbar-nav d-lg-block d-none">
							<li className="nav-item">
								<a href="/orders" className="btn btn-sm mb-0 bg-gradient-dark">{langs.myOrders}</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>

		</>
	);
}

const mapStateToProps = (state) => {
	const {
		isAuthLoading,
	} = state.auth;
	return {
		isAuthLoading,
		user: state.auth.currentUser
	}
}

const mapDispatchToProps = (dispatch) => ({
	onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
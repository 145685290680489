import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../../../helpers';
import ActionTypes from '../../../store/action-types';
import Layout from '../../../layouts/layout'
import DashboardLayout from '../../../layouts/dashboard'
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const Home = ({ user, onUserLogout }) => {
	const [mobile, setMobile] = useState(null)
	const params= useParams()
	const [config, setConfig] = useState({});
	const [inputs, setInput] = useState([]);
	const logOut = () => {
		onUserLogout();
		history.push('/login');
	}
	
	const get = () => {
		http.get(`/users/${window.location.pathname == "/profile" ? user.id : params.id}/edit`, {})
		.then(response => {
			if (response.data) {
				console.log(response.data.data.information)
				setMobile(response.data.data.information.phone_number)
				setInput(response.data.data);
				setConfig(response.data);
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}

	useEffect(() => {
		get();
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		const elm = e.target;
		const formData = new FormData(elm);
		formData.append('phone_number', mobile);
		http.post(`/users/${window.location.pathname == "/profile" ? user.id : params.id}?_method=put`, formData).then(response => {
			if (response.data) {
				toast.success(response.data.message);
				if(window.location.pathname != "/profile")
					history.push('/dashboard/users')
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}
	
  return (
    <Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100"} >
      <DashboardLayout>

		<form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="card border shadow-xs mb-4">
              <div className="card-header border-bottom pb-0">
                <div className="d-sm-flex align-items-center">
                  <div>
                    <h6 className="font-weight-semibold text-lg mb-0">{langs.updateUser}</h6>
                    <p className="text-sm">{langs.updateUserNotic}</p>
  				  </div>
 
                </div>
              </div>
              <div className="card-body px-0 py-0">
                <div className="form-crul">
				
				
					<label>{langs.username}</label>
					<div className="mb-3">
						<input type="text" name={`username`} className="form-control " aria-label="username" aria-describedby="username-addon" required disabled={true} value={
							config?.data?.username
						}/>
					</div>
						
					{ /*
					<label>password</label>
					<div className="mb-3">
						<input type="password" name={`password`} className="form-control " aria-label="password" aria-describedby="password-addon" />
					</div>
					*/ }

					{ config?.information?.length > 0 ? 
						<>
						{config.information.map((information, index) => (
							<div className="row" key={index}>
								<div className="col-12" >
									<label>{langs[information]}</label>
									
									{
										["id_card_path", "id_card_back_path"].includes(information) && 
										inputs?.information[information.replace('_path', '_image')] ?
										<>
											<img className="w-full mb-3" src={inputs?.information[information.replace('_path', '_image')]} />
										</>
										: <></> 
									}
									 
 										<>
											<div className="mb-3">
												{ ["phone_number"].includes(information) ? <>
													<PhoneInput
														placeholder="Enter phone number"
														value={mobile}
														international
														withCountryCallingCode
														onChange={setMobile}/>
												</> : <>
											
													<input type={["id_card_path", "id_card_back_path"].includes(information) ? "file" : "text"} name={information} 
													disabled={ false}
													className="form-control"
													value={
														!["id_card_path", "id_card_back_path"].includes(information) ? inputs?.information[information] : null
													}
													onChange={ (e) => {
														if(!["id_card_path", "id_card_back_path"].includes(information)){
															const information = {}
															information[information] = e.target.value;
															setInput({
																...inputs, information
															})
														}
													} }
													/>
												</>}

												
											</div>
										</> 
 								</div>
							</div>
						))}
						</> : null
					}
					
					{ user.role == "admin" && window.location.pathname != "/profile" && config?.statuses?.length > 0 ? 
						<>
							<hr></hr>
							<div><h6 class="font-weight-semibold text-lg mb-0">{langs.status}</h6><p class="text-sm">{langs.statusNotic}</p></div>
							
							<label>{langs.status}</label>
							<div className="mb-3">
								<select name="status_id" 
								disabled={  false}
								className="form-control" aria-label="status_id" aria-describedby="title-addon" required >
									{config.statuses.map((status, index) => (
										<option key={index} value={status.id} selected={
											config?.data?.status_id == status.id ? true : false
										} >{status.label}</option>
									))}
								</select>
							</div>
							
							<label>{langs.role}</label>
							<div className="mb-3">
								<select name="role" 
								disabled={  false}
								className="form-control" aria-label="role" aria-describedby="title-addon" required >
									{config.roles.map((role, index) => (
										<option key={index} value={role} selected={
											config?.data?.role == role ? true : false
										} >{langs[role]}</option>
									))}
								</select>
							</div>
							
							{
								user.role == "admin" && config?.data?.role == "operator" ? <>
									
								{ user.role == "admin" && config?.percents?.length > 0 ? 
									<>
									{config.percents.map((percents, index) => (
										<div className="row" key={index}>
											<div className="col-12" >
												<label>{langs[percents]} {langs.percent}</label>
												<div className="mb-3">
													<input type={"number"} min={0} max={100} name={`${percents}_percent`} className="form-control" value={config?.data[`${percents}_percent`]}
													onChange={ (e) => {
														var data = config.data;
														data[`${percents}_percent`] = e.target.value;
														setConfig({
															...config, data
														})
													} }
													/>
												</div>
											</div>
										</div>
									))}
									</> : null
								}
									
									<label>{langs.clearing}</label>
									<p style={{ fontSize: 15 }}>
										<b>{langs.clearing_now}: </b> {config?.data?.equity} 
									</p>
									<div className="mb-3">
										<input type="number" min="0" name={`equity`} className="form-control " aria-label="equity" aria-describedby="equity-addon" 										
										/>
									</div>
									
								</> : <>
								
								</>
								
							}

							
							
							
						</> : null
					}
					
					{ window.location.pathname   ?
						<>
						<div className="flex" style={{"justify-content": "space-between"}}>
							<div className="text-center">
							  <button type="submit" className="btn btn-dark ">{langs.update}</button>
							</div>
							<div className="text-center">
							  <button type="submit" className="btn btn-dark " >{langs.save}</button>
							</div>
						</div>
						</>
						: <></>
						
					}
              </div>
              </div>
            </div>
          </div>
        </div>
		</form>
      </DashboardLayout>
    </Layout>

  );
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
import actionTypes from '../../action-types/';
import { history } from '../../../helpers';
import { http } from '../../../helpers';
import { toast } from 'react-toastify';

export const loginWithJWT = (user) => (dispatch) => {
	const forward = localStorage.forward;
	console.log(user.access_token)
	dispatch({ type: actionTypes.AUTH_LOADING, loading: true });
	dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
    dispatch({
      type: actionTypes.LOGIN_USER,
      currentUser: user.user,
      token: user.access_token
    })
	/*
	if(forward) {
		localStorage.removeItem('forward');
		window.location.href = forward;
	} else
		history.push("/")
	*/
	history.push("/")
}

import { useState, useEffect, React } from "react";
import Config from "../config/app";
import { connect } from 'react-redux';
import { history , langs, http } from '../helpers';
import ActionTypes from '../store/action-types';

import en from '../assets/langs/en.png';
import ar from '../assets/langs/ar.png';


const Aside = ({
	children, onUserLogout, user,
}) => {
	
	const logOut = () => {
		localStorage.setItem('lang', "en");
		localStorage.setItem('theme', "ltr");
		
		onUserLogout();
		
					window.location.href = '/login'
		/*
		history.push('/login');
		setTimeout(function(){
			window.location.href = '/login'
		}, 500)
		*/
	}

	const [menuBar, setMenuBar] = useState("false");
	const [pathname, setPathname] = useState(window.location.pathname);
	const [total, setTotal] = useState(0);
	
	useEffect(() => {
		setPathname(window.location.pathname);
		console.log(pathname)
		
		
	  if(user?.role == "admin" || user?.role == "operator"){
	
		setInterval(function(){
		
		http.get(`/orders?pathname=/dashboard/orders`, {})
		  .then(response => {
			if (response.data) {
			  setTotal(response.data.total)
			}
		  })
	
		}, 10000);
	  }
		
		
	}, [window.location.pathname]);

	const lang = (l, t) => {
		localStorage.setItem('lang', l);
		localStorage.setItem('theme', t);
		document.querySelector('.lang.en').classList.remove('active')
		document.querySelector('.lang.fak').classList.remove('active')
		document.querySelector('.lang.ar').classList.remove('active')
		document.querySelector(`.lang.${l == "fa" ? "fak" : l}`).classList.add('active')
		
		window.location.reload()
	}
	
	return (
		<>
			<div className="sidenav-header">
				<i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
				<a className="navbar-brand d-flex align-items-center m-0" href="/" >
					<img src={`/menu-logo.png`} />
				</a>
			</div>
			<div className="collapse navbar-collapse px-4  w-auto " id="sidenav-collapse-main">
				<ul className="navbar-nav">
				
					<div className="row langs">
					
						
						<div onClick={() => { lang('ar', 'rtl') }} 
							className={`col-4 lang ar ${localStorage.lang == "ar" ? "active" : ""}`}>Arabic</div>
							
						<div onClick={() => { lang('en', 'ltr') }} 
							className={`col-4 lang en ${localStorage.lang == "en" ? "active" : ""}`}>English</div>
							
						<div onClick={() => { lang('fa', 'rtl') }} 
							className={`col-4 lang fak ${localStorage.lang == "fa" ? "active" : ""}`}>Farsi</div>
					</div>
				
					<li className="nav-item">
						<a className={`nav-link ${pathname  == "/" ? "active" : ""}`} href="/">
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<svg width="35px" height="35px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
									<title>dashboard</title>
									<g id="dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
										<g id="template" transform="translate(12.000000, 12.000000)" fill="#FFFFFF" fillRule="nonzero">
											<path className="color-foreground" d="M0,1.71428571 C0,0.76752 0.76752,0 1.71428571,0 L22.2857143,0 C23.2325143,0 24,0.76752 24,1.71428571 L24,5.14285714 C24,6.08962286 23.2325143,6.85714286 22.2857143,6.85714286 L1.71428571,6.85714286 C0.76752,6.85714286 0,6.08962286 0,5.14285714 L0,1.71428571 Z" id="Path"></path>
											<path className="color-background" d="M0,12 C0,11.0532171 0.76752,10.2857143 1.71428571,10.2857143 L12,10.2857143 C12.9468,10.2857143 13.7142857,11.0532171 13.7142857,12 L13.7142857,22.2857143 C13.7142857,23.2325143 12.9468,24 12,24 L1.71428571,24 C0.76752,24 0,23.2325143 0,22.2857143 L0,12 Z" id="Path"></path>
											<path className="color-background" d="M18.8571429,10.2857143 C17.9103429,10.2857143 17.1428571,11.0532171 17.1428571,12 L17.1428571,22.2857143 C17.1428571,23.2325143 17.9103429,24 18.8571429,24 L22.2857143,24 C23.2325143,24 24,23.2325143 24,22.2857143 L24,12 C24,11.0532171 23.2325143,10.2857143 22.2857143,10.2857143 L18.8571429,10.2857143 Z" id="Path"></path>
										</g>
									</g>
								</svg>
							</div>
							<span className="nav-link-text ms-1">{langs.dashboard}</span>
						</a>
					</li>
					
					{ user?.role == "admin" || user?.role == "operator" ? <>
					<li className="nav-item">
						<a className={`nav-link ${['/dashboard/products', '/dashboard/products/create'].includes(pathname) ? "active" : ""}`} href="/dashboard/products">
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<svg width="35px" height="35px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
									<title>table</title>
									<g id="table" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
										<g id="view-grid" transform="translate(12.000000, 12.000000)" fill="#FFFFFF" fillRule="nonzero">
											<path className="color-foreground" d="M3.42857143,0 C1.53502286,0 0,1.53502286 0,3.42857143 L0,6.85714286 C0,8.75069143 1.53502286,10.2857143 3.42857143,10.2857143 L6.85714286,10.2857143 C8.75069143,10.2857143 10.2857143,8.75069143 10.2857143,6.85714286 L10.2857143,3.42857143 C10.2857143,1.53502286 8.75069143,0 6.85714286,0 L3.42857143,0 Z" id="Path"></path>
											<path className="color-background" d="M3.42857143,13.7142857 C1.53502286,13.7142857 0,15.2492571 0,17.1428571 L0,20.5714286 C0,22.4650286 1.53502286,24 3.42857143,24 L6.85714286,24 C8.75069143,24 10.2857143,22.4650286 10.2857143,20.5714286 L10.2857143,17.1428571 C10.2857143,15.2492571 8.75069143,13.7142857 6.85714286,13.7142857 L3.42857143,13.7142857 Z" id="Path"></path>
											<path className="color-background" d="M13.7142857,3.42857143 C13.7142857,1.53502286 15.2492571,0 17.1428571,0 L20.5714286,0 C22.4650286,0 24,1.53502286 24,3.42857143 L24,6.85714286 C24,8.75069143 22.4650286,10.2857143 20.5714286,10.2857143 L17.1428571,10.2857143 C15.2492571,10.2857143 13.7142857,8.75069143 13.7142857,6.85714286 L13.7142857,3.42857143 Z" id="Path"></path>
											<path className="color-foreground" d="M13.7142857,17.1428571 C13.7142857,15.2492571 15.2492571,13.7142857 17.1428571,13.7142857 L20.5714286,13.7142857 C22.4650286,13.7142857 24,15.2492571 24,17.1428571 L24,20.5714286 C24,22.4650286 22.4650286,24 20.5714286,24 L17.1428571,24 C15.2492571,24 13.7142857,22.4650286 13.7142857,20.5714286 L13.7142857,17.1428571 Z" id="Path"></path>
										</g>
									</g>
								</svg>
							</div>
							<span className="nav-link-text ms-1">{langs.products}</span>
						</a>
					</li>
					</> : <></> }
					
					{ user?.role == "admin" || user?.role == "operator" ? <>
					<li className="nav-item">
						<a className={`nav-link ${['/dashboard/users', '/dashboard/users/create'].includes(pathname) ? "active" : ""}`} href="/dashboard/users">
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<svg width="35px" height="35px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
									<title>wallet</title>
									<g id="wallet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
										<g id="credit-card" transform="translate(12.000000, 15.000000)" fill="#FFFFFF">
											<path className="color-background" d="M3,0 C1.343145,0 0,1.343145 0,3 L0,4.5 L24,4.5 L24,3 C24,1.343145 22.6569,0 21,0 L3,0 Z" id="Path" fillRule="nonzero"></path>
											<path className="color-foreground" d="M24,7.5 L0,7.5 L0,15 C0,16.6569 1.343145,18 3,18 L21,18 C22.6569,18 24,16.6569 24,15 L24,7.5 Z M3,13.5 C3,12.67155 3.67158,12 4.5,12 L6,12 C6.82842,12 7.5,12.67155 7.5,13.5 C7.5,14.32845 6.82842,15 6,15 L4.5,15 C3.67158,15 3,14.32845 3,13.5 Z M10.5,12 C9.67158,12 9,12.67155 9,13.5 C9,14.32845 9.67158,15 10.5,15 L12,15 C12.82845,15 13.5,14.32845 13.5,13.5 C13.5,12.67155 12.82845,12 12,12 L10.5,12 Z" id="Shape"></path>
										</g>
									</g>
								</svg>
							</div>
							<span className="nav-link-text ms-1">{langs.users}</span>
						</a>
					</li>
					</> : <></> }

					{ user?.role == "admin" || user?.role == "operator" ? <>
					
					<li className="nav-item menu-with-icon">
						<a className={`nav-link ${['/dashboard/orders'].includes(pathname) ? "active" : ""}`} href="/dashboard/orders">
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<i className="fa-solid fa-cart-shopping"></i>
							</div>
							<span className="nav-link-text ms-1">{langs.orders}
								<b className="badge-count">{total}</b>
							</span>
						</a>
					</li>
					
					<li className="nav-item menu-with-icon">
						<a className={`nav-link ${['/dashboard/reports'].includes(pathname) ? "active" : ""}`} href="/dashboard/reports">
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<i className="fa-solid fa-cart-shopping"></i>
							</div>
							<span className="nav-link-text ms-1">{langs.reports}</span>
						</a>
					</li>
					
					</> : <></> }


					{ !user || user?.role == "customer" ? <>
					
					<li className="nav-item">
						<a className={`nav-link ${['/products'].includes(pathname) ? "active" : ""}`} href="/products">
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<svg width="35px" height="35px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
									<title>table</title>
									<g id="table" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
										<g id="view-grid" transform="translate(12.000000, 12.000000)" fill="#FFFFFF" fillRule="nonzero">
											<path className="color-foreground" d="M3.42857143,0 C1.53502286,0 0,1.53502286 0,3.42857143 L0,6.85714286 C0,8.75069143 1.53502286,10.2857143 3.42857143,10.2857143 L6.85714286,10.2857143 C8.75069143,10.2857143 10.2857143,8.75069143 10.2857143,6.85714286 L10.2857143,3.42857143 C10.2857143,1.53502286 8.75069143,0 6.85714286,0 L3.42857143,0 Z" id="Path"></path>
											<path className="color-background" d="M3.42857143,13.7142857 C1.53502286,13.7142857 0,15.2492571 0,17.1428571 L0,20.5714286 C0,22.4650286 1.53502286,24 3.42857143,24 L6.85714286,24 C8.75069143,24 10.2857143,22.4650286 10.2857143,20.5714286 L10.2857143,17.1428571 C10.2857143,15.2492571 8.75069143,13.7142857 6.85714286,13.7142857 L3.42857143,13.7142857 Z" id="Path"></path>
											<path className="color-background" d="M13.7142857,3.42857143 C13.7142857,1.53502286 15.2492571,0 17.1428571,0 L20.5714286,0 C22.4650286,0 24,1.53502286 24,3.42857143 L24,6.85714286 C24,8.75069143 22.4650286,10.2857143 20.5714286,10.2857143 L17.1428571,10.2857143 C15.2492571,10.2857143 13.7142857,8.75069143 13.7142857,6.85714286 L13.7142857,3.42857143 Z" id="Path"></path>
											<path className="color-foreground" d="M13.7142857,17.1428571 C13.7142857,15.2492571 15.2492571,13.7142857 17.1428571,13.7142857 L20.5714286,13.7142857 C22.4650286,13.7142857 24,15.2492571 24,17.1428571 L24,20.5714286 C24,22.4650286 22.4650286,24 20.5714286,24 L17.1428571,24 C15.2492571,24 13.7142857,22.4650286 13.7142857,20.5714286 L13.7142857,17.1428571 Z" id="Path"></path>
										</g>
									</g>
								</svg>
							</div>
							<span className="nav-link-text ms-1">{langs.products}</span>
						</a>
					</li>


					<li className="nav-item menu-with-icon">
						<a className={`nav-link ${['/orders'].includes(pathname) ? "active" : ""}`} href="/orders">
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<svg width="35px" height="35px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
									<title>rtl</title>
									<g id="rtl" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
										<g id="menu-alt-3" transform="translate(12.000000, 14.000000)" fill="#FFFFFF">
											<path className="color-foreground" d="M0,1.71428571 C0,0.76752 0.76752,0 1.71428571,0 L22.2857143,0 C23.2325143,0 24,0.76752 24,1.71428571 C24,2.66105143 23.2325143,3.42857143 22.2857143,3.42857143 L1.71428571,3.42857143 C0.76752,3.42857143 0,2.66105143 0,1.71428571 Z" id="Path"></path>
											<path className="color-background" d="M0,10.2857143 C0,9.33894857 0.76752,8.57142857 1.71428571,8.57142857 L22.2857143,8.57142857 C23.2325143,8.57142857 24,9.33894857 24,10.2857143 C24,11.2325143 23.2325143,12 22.2857143,12 L1.71428571,12 C0.76752,12 0,11.2325143 0,10.2857143 Z" id="Path"></path>
											<path className="color-background" d="M10.2857143,18.8571429 C10.2857143,17.9103429 11.0532343,17.1428571 12,17.1428571 L22.2857143,17.1428571 C23.2325143,17.1428571 24,17.9103429 24,18.8571429 C24,19.8039429 23.2325143,20.5714286 22.2857143,20.5714286 L12,20.5714286 C11.0532343,20.5714286 10.2857143,19.8039429 10.2857143,18.8571429 Z" id="Path"></path>
										</g>
									</g>
								</svg>
							</div>
							<span className="nav-link-text ms-1">{langs.myOrders}</span>
						</a>
					</li>
					</> : <></> }


					{ !user || user?.role == "customer" ? <>
					<li className="nav-item menu-with-icon">
						<a className={`nav-link ${['/policy'].includes(pathname) ? "active" : ""}`} href="/policy">
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<i className="fa-solid fa-blog"></i>
							</div>
							<span className="nav-link-text ms-1">{langs.policy}</span>
						</a>
					</li>
					</> : <></> }
					
					{ !user || user?.role == "customer" ? <>
					<li className="nav-item menu-with-icon">
						<a className={`nav-link ${['/about'].includes(pathname) ? "active" : ""}`} href="/about">
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<i className="fa-solid fa-blog"></i>
							</div>
							<span className="nav-link-text ms-1">{langs.aboutus}</span>
						</a>
					</li>
					</> : <></> 
					}
					
					
					{ /* !user || user?.role == "customer" ? <>
					<li className="nav-item menu-with-icon">
						<a className={`nav-link`} href={`${process.env.REACT_APP_API_URL}/contactus`}>
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<i className="fa-solid fa-blog"></i>
							</div>
							<span className="nav-link-text ms-1">{langs.contactus}</span>
						</a>
					</li>
					</> : <></> */ }
					
					
					{ user ? <>
					
					
					
					<li className="nav-item">
						<a className={`nav-link`} href="#" onClick={logOut}>
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className=" text-gold ">
									<path fill-rule="evenodd" d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z" clip-rule="evenodd" />
								</svg>
							</div>
							<span className="nav-link-text ms-1">{langs.logout}</span>
						</a>
					</li>
					</> : <>
						<li className="nav-item menu-with-icon">
							<a className={`nav-link ${['/login'].includes(pathname) ? "active" : ""}`} href="/login">
							<div className="icon icon-shape icon-sm px-0 text-center d-flex align-items-center justify-content-center">
								<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className=" text-gold ">
									<path fill-rule="evenodd" d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z" clip-rule="evenodd" />
								</svg>
							</div>
								<span className="nav-link-text ms-1">{langs.login}</span>
							</a>
						</li>
					</> }

				</ul>
			</div>
			{ user?.role != "admin" ? ( <>
			<div className="sidenav-footer mx-4 mt-4 ">
				<div className="card border-radius-md" id="sidenavCard">
					<div className="card-body  text-start  p-3 w-100">
						<div className="mb-3">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="text-primary docs-svg" viewBox="0 0 24 24" fill="currentColor" id="sidenavCardIcon">
								<path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="docs-info">
							<h6 className="font-weight-bold up mb-2">{langs.profile}</h6>
							<p className="text-sm font-weight-normal">{langs.profileNotic}</p>
							<a href="/profile" className="font-weight-bold text-sm mb-0 icon-move-right mt-auto w-100 mb-0">
								{langs.profileComplete}
								<i className="fas fa-arrow-right-long text-sm ms-1" aria-hidden="true"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
			</> ) : null }
		</>
	);
}

const mapStateToProps = (state) => {
	const {
		isAuthLoading,
	} = state.auth;
	return {
		isAuthLoading,
		user: state.auth.currentUser
	}
}

const mapDispatchToProps = (dispatch) => ({
	onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Aside);
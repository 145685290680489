import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { history, langs } from '../helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { loginWithJWT } from '../store/actions/auth/loginAction'
import Layout from '../layouts/layout'
//import imageSignIn from '../assets/img/image-sign-in.jpg'
import imageSignIn from '../assets/img/1.jpg'

const Login = ({
	isAuthLoading,
	onUserLogin
}) => {
	const formik = useFormik({
		initialValues: {
			username: '',
			password: ''
		},
		validationSchema: Yup.object({
			username: Yup.string('Enter your username')
				.required('username is required'),
			password: Yup.string('Enter your password')
				.min(6, 'Password should be of minimum 6 characters length')
				.max(30, 'Password should be of minimum 30 characters length')
				.required('Password is required')
		}),
		onSubmit: (values) => {
			onUserLogin(values);
		}
	});
	return (
		<Layout auth={true}>
			<section className="auth-form">
				<div className="page-header min-vh-100">
					<div className="container">
						<div className="row" dir="ltr">
							<div className="col-xl-4 col-md-6 d-flex flex-column mx-auto">
								<div className="card card-plain ">
									<div className="card-header pb-0 text-left bg-transparent">
										<div className="text-center mb-5">
											<img src="/logo.png" className="" />
										</div>

										<h3 className="font-weight-black text-dark display-6">{langs.welcome}</h3>
										<p className="mb-0">{langs.welcomeNotic}</p>
									</div>
									<div className="card-body">
										<form onSubmit={formik.handleSubmit} >
											<label>{langs.username}
												<b className={Boolean(formik.errors.username) ? 'text-error' : ''}>{formik.errors.username}</b>
											</label>
											<div className="mb-3">
												<input
													className={`form-control ${Boolean(formik.errors.name) ? 'is-invalid' : 'is-valid'} `}
													variant="outlined"
													margin="normal"
													fullWidth
													id="username"
													label="username Address"
													name="username"
													value={formik.values.username}
													onChange={formik.handleChange}
													error={formik.touched.username && Boolean(formik.errors.username)}
													helperText={formik.touched.username && formik.errors.username}
												/>
											</div>
											<label>{langs.password}
												<b className={Boolean(formik.errors.password) ? 'text-error' : ''}>{formik.errors.password}</b>
											</label>
											<div className="mb-3">
												<input
													className={`form-control ${Boolean(formik.errors.name) ? 'is-invalid' : 'is-valid'} `}
													variant="outlined"
													margin="normal"
													fullWidth
													name="password"
													label="Password"
													type="password"
													id="password"
													autoComplete="current-password"
													value={formik.values.password}
													onChange={formik.handleChange}
													error={formik.touched.password && Boolean(formik.errors.password)}
													helperText={formik.touched.password && formik.errors.password}
												/>
											</div>

											<div className="text-center">
												<button
													type="submit"
													fullWidth
													variant="contained"

													className="btn btn-dark w-100 mt-4 mb-3">
													{isAuthLoading && <CircularProgress color="info" size={14} />}
													{!isAuthLoading && langs.login}
												</button>
											</div>
										</form>
									</div>
									<div className="card-footer text-center pt-0 px-lg-2 px-1">
										<p className="text-center mx-auto">
											
											<a href="/register" className="text-dark font-weight-bold font-13">{langs.registerNotic}</a>
										</p>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}
const mapStateToProps = (state) => {
	const {
		isAuthLoading,
	} = state.auth;
	return {
		isAuthLoading,
	}
}
const mapDispatchToProps = (dispatch) => ({
	onUserLogin: (token) => dispatch(loginWithJWT(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
import { useState, useEffect, React } from "react";
import Config from "../config/app";
import { connect } from 'react-redux';
import { history, langs } from '../helpers';
import ActionTypes from '../store/action-types';
import Aside from './aside';
import Navbar from './navbar';

const Layout = ({
	children, onUserLogout, foo, user, dashboard, beforeMain
}) => {

	const logOut = () => {
		onUserLogout();
		history.push('/login');
	}

	const [menuBar, setMenuBar] = useState("false");
	const [rtl, setRtl] = useState(localStorage.theme);

	const copyrightTime = new Date().getFullYear();

	return (
		<>
			<div className="container-fluid dashboard-fluid">
				{children}
				
				<footer className="footer pt-3  hidden ">
					<div className="container-fluid">
						<div className="row align-items-center justify-content-lg-between">
							<div className="col-lg-12 mb-lg-0 mb-4">
								<div className="copyright text-center text-xs text-muted ">
									© {langs.copyRight}
								</div>
							</div>
							<div className="col-lg-6 hidden">
								<ul className="nav nav-footer justify-content-center justify-content-lg-end">
									<li className="nav-item">
										<a href="/" className="nav-link text-xs text-muted" target="_blank">{langs.dashboard}</a>
									</li>
									<li className="nav-item">
										<a href="#" className="nav-link text-xs text-muted" target="_blank">{langs.aboutUs}</a>
									</li>
									<li className="nav-item">
										<a href="#" className="nav-link text-xs text-muted" target="_blank">{langs.blog}</a>
									</li>

								</ul>
							</div>
						</div>
					</div>
				</footer>


			</div>

			
			{
			/*				
			user.role == "customer" ? 
				<>
			
				<div className="mobile-toolbar">
				
					<div className="mobile-toolbar-body">
					
						<div className={`mobile-toolbar-item home ${window.location.pathname == "/" ? "active" : ""}`}><a href="/" ></a></div>
						<div className={`mobile-toolbar-item products ${window.location.pathname == "/products" ? "active" : ""}`}><a href="/products" ></a></div>
						<div className={`mobile-toolbar-item orders ${window.location.pathname == "/orders" ? "active" : ""}`}><a href="/orders" ></a></div>
						<div className={`mobile-toolbar-item profile ${window.location.pathname == "/profile" ? "active" : ""}`}><a href="/profile" ></a></div>
					</div>
				</div>
				</>
			: <></>
			*/
			}


		</>
	);
}

const mapStateToProps = (state) => {
	const {
		isAuthLoading,
	} = state.auth;
	return {
		isAuthLoading,
		user: state.auth.currentUser
	}
}

const mapDispatchToProps = (dispatch) => ({
	onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
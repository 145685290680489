import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../../../helpers';
import ActionTypes from '../../../store/action-types';
import Layout from '../../../layouts/layout'
import DashboardLayout from '../../../layouts/dashboard'
import { toast } from 'react-toastify';
 
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

 
const Home = ({ user, onUserLogout }) => {

  const [products, setProducts] = useState({ data: [] });
	const [option, setOptions] = useState({ status_id: "", from: "", to: "", user_id: "", page: 1, operator_id: 0});
	const [config, setConfig] = useState({});

	
	const [orderModal, setOrderModal] = useState(null);
	const [days, setDays] = useState([]);
	
	
	const check = (price_id, product_id, product, type) => {
		var iid = "b-" + new Date().getTime();
		http.get(`/orders/times?id=${product_id}`, {}).then(response => {
			if (response.data) {
			  console.log(response.data.map((e) => new Date(e)))
				setDays(response.data.map((e) => new Date(e)));
				setOrderModal({price_id, product_id, product, type, iid })
			}
		  })
		  .catch(err => {
			toast.error(err.message);
		  })
	}	
	
	const buy = (price_id, product_id, details = "") => {
		http.post(`/orders`, {
			product_id: product_id,
			price_id, 
			details
			
		})
		  .then(response => {
			if (response.data) {
				//get();
				toast.success(langs.reserved_successfully)
								history.push('/orders');


			}
		  })
		  .catch(err => {
			toast.error(err.message);
		  })
	}
	
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndtDate] = useState(null);
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;

		return [year, month, day].join('-');
	}
	
	
	
	
  const logOut = () => {
    onUserLogout();
    history.push('/login');
  }

  const s = () => {
	if(window.location.pathname != "/orders"){
		http.get("/orders/create", {})
		.then(response => {
			if (response.data) {
				setConfig(response.data);
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}
  
  }
  
  const get = () => {
	  setProducts({ data: [] });
    http.get(`/products?type=panel&operator_id=${option.operator_id}`, {})
      .then(response => {
        if (response.data) {
          console.log(response.data)
          setProducts(response.data);
        }
      })
      .catch(err => {
        toast.error(err.message);
      })
  }

  const deleteRecord = (id) => {
	const ok = window.confirm(langs.ays);
	if(ok){
		http.post(`/products/${id}?_method=delete`, {}).then(response => {
			if (response.data) {
				setProducts({...products , data: products.data.filter(e => e.id != id)});
				toast.success(response.data.message);

			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}
	
  }

  useEffect(() => {
    s();
    get();
  }, []);

  return (
    <Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100"} >
      <DashboardLayout>


		{ orderModal ? 
				<>
				<div className="order-box">
					<div>
						<p className="title ">{orderModal.product.title}</p>
						<div>
							<div className="order-box-div">
								<label className="label">{langs.rsv_time}</label>
								<div className="custom-style component-wrapper mx-2 i-from" id={orderModal.iid}>
									    <DatePicker 
											excludeDates={days}
											onChange={(date) => setStartDate((date))} 
											placeholderText={langs.ch_time} 
											selected={startDate}
											minDate={new Date()}
											disabledKeyboardNavigation
											 
										/>
										{ //<jb-date-input format="YYYY-MM-DD" placeholder={langs.ch_time} input-type={localStorage.lang == "fa" ? "JALALI" : "GREGORIAN"} ></jb-date-input>
										}
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.end_time}</label>
								<div className="custom-style component-wrapper mx-2 i-to" id={orderModal.iid}>
									<DatePicker 
										excludeDates={days}
										onChange={(date) => { setEndtDate(date) }} 
										placeholderText={langs.ch_time} 
										selected={endDate}
										minDate={startDate}
											disabledKeyboardNavigation
											 
									/>
									{ //<jb-date-input format="YYYY-MM-DD" placeholder={langs.ch_time} input-type={localStorage.lang == "fa" ? "JALALI" : "GREGORIAN"} ></jb-date-input>
									}
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.dep_type}</label>
								<div>
									<input type="radio" className="radio" name="drone" value="huey" checked />
									<label className="radio-txt">{langs.cash}</label>
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.pick_type}</label>
								<div className="text-center">
									<select className="pickup_place">
										<option value="airport">{langs.airport}</option>
										<option value="hotel">{langs.hotel}</option>
										<option value="shop">{langs.shop}</option>
									</select>
								</div>
							</div>
							<div className="order-box-div">
								<label className="label">{langs.addr_type}</label>
								<div className="text-center">
									<textarea className="details"></textarea>
								</div>
							</div>
							<div className="flex">
								<div className="w-half"><a href="#" className="close" onClick={() => { setOrderModal(null) }}>{langs.close}</a></div>
								<div className="w-half"><a href="#" className="send" onClick={() => {
									buy(
										orderModal.price_id,
										orderModal.product_id,
										{
											from: formatDate(startDate),
											to: formatDate(endDate),
											place: document.querySelector('.pickup_place').value,
											detail: document.querySelector('.details').value,
										}
									);
									setOrderModal(null)
									
								}}>{langs.send_request}</a></div>
							</div>
							
						</div>
					</div>
				</div>
			</>
			: null }
			
		
	  
	  
        <div className="row">
          <div className="col-12">
            <div className="card border shadow-xs mb-4">
              <div className="card-header border-bottom pb-0">
                <div className="d-sm-flex align-items-center">
                  <div>
                    <h6 className="font-weight-semibold text-lg mb-0">{langs.products}</h6>
                    <p className="text-sm">{langs.createProductNotic}</p>
                  </div>
                  <div className="ms-auto d-flex">
                    <a href='/dashboard/products/create' type="button" className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2">
                      <span className="btn-inner--text">{langs.create}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body px-0 py-0">
			  
	
					{ user.role == "admin"  && config?.operators?.length > 0 ? 
						<>
							<div className="" style={{margin: "10px"}}>
								<select onChange={(e) => { 
									var json = option;
									json.operator_id = Number(e.target.value) 
									json.page = 1;
									setOptions(json);
									get();
								}} className="form-control " style={{fontSize: "13px", fontWeight: 500 }} >
									<option value="">{langs.operator}: {langs.allItems}</option>
									{config.operators.map((operator, index) => (
										<option key={index} value={operator.id}>{langs.operator}: {operator.username}</option>
									))}
								</select>
							</div>
						</> : null
					}
					
                <div className="table-responsive p-0">
                  <table className="table align-items-center mb-0">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="text-secondary text-xs font-weight-semibold opacity-7">{langs._id}</th>
                        <th className="text-secondary text-xs font-weight-semibold opacity-7">{langs.product}</th>
                        <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">{langs.price}</th>
                        <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">{langs.status}</th>
                        <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">{langs.created_at}</th>
                        <th className="text-secondary opacity-7"></th>
                        <th className="text-secondary opacity-7"></th>
                        <th className="text-secondary opacity-7"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.data.map((product, index) => (

                        <tr key={index}>
                          <td className="align-middle text-center text-sm">
                            <p className="text-sm text-secondary mb-0">{product._id}</p>
                          </td>
                          <td>
                            <div className="d-flex px-2 py-1">
							{product?.media?.length > 0 ? 
								<>
								  <div className="d-flex align-items-center">
									<img src={product.media[0].url} className="avatar avatar-sm rounded-circle me-2" alt="user1" />
								  </div>
								</>
							: null} 
                              <div className="d-flex flex-column justify-content-center ms-1">
                                <h6 className="mb-0 text-sm font-weight-semibold">{product.title}</h6>
                                <p className="text-sm text-secondary mb-0">{product.category[0].title}</p>
                                <p className="text-sm text-secondary mb-0">
									{product?.user?.information?.name}
									<small style={{ margin: 5}}>({product?.user?.information?.nick_name})</small>
								</p>
                              </div>
                            </div>
                          </td>
                          <td>
							{product?.price?.length > 0 ? 
								<>
									<p className="text-sm text-dark font-weight-semibold mb-0">{product.price[0].label}</p>
									<p className="text-sm text-secondary mb-0">{product.price[0].price}</p>
								</>
							: null} 

                          </td>
                          <td className="align-middle text-center text-sm">
                            <span className={`badge badge-sm border border-success text-success bg-success`}>{product?.status?.label}</span>
                          </td>
                          <td className="align-middle text-center">
                            <span className="text-secondary text-sm font-weight-normal">{product.created_time}</span>
                          </td>
                          <td className="align-middle">
                            <a href={`/dashboard/products/${product.id}/edit`} className="text-secondary font-weight-bold text-xs" data-bs-toggle="tooltip" data-bs-title="Edit user">
                              <svg width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.2201 2.02495C10.8292 1.63482 10.196 1.63545 9.80585 2.02636C9.41572 2.41727 9.41635 3.05044 9.80726 3.44057L11.2201 2.02495ZM12.5572 6.18502C12.9481 6.57516 13.5813 6.57453 13.9714 6.18362C14.3615 5.79271 14.3609 5.15954 13.97 4.7694L12.5572 6.18502ZM11.6803 1.56839L12.3867 2.2762L12.3867 2.27619L11.6803 1.56839ZM14.4302 4.31284L15.1367 5.02065L15.1367 5.02064L14.4302 4.31284ZM3.72198 15V16C3.98686 16 4.24091 15.8949 4.42839 15.7078L3.72198 15ZM0.999756 15H-0.000244141C-0.000244141 15.5523 0.447471 16 0.999756 16L0.999756 15ZM0.999756 12.2279L0.293346 11.5201C0.105383 11.7077 -0.000244141 11.9624 -0.000244141 12.2279H0.999756ZM9.80726 3.44057L12.5572 6.18502L13.97 4.7694L11.2201 2.02495L9.80726 3.44057ZM12.3867 2.27619C12.7557 1.90794 13.3549 1.90794 13.7238 2.27619L15.1367 0.860593C13.9869 -0.286864 12.1236 -0.286864 10.9739 0.860593L12.3867 2.27619ZM13.7238 2.27619C14.0917 2.64337 14.0917 3.23787 13.7238 3.60504L15.1367 5.02064C16.2875 3.8721 16.2875 2.00913 15.1367 0.860593L13.7238 2.27619ZM13.7238 3.60504L3.01557 14.2922L4.42839 15.7078L15.1367 5.02065L13.7238 3.60504ZM3.72198 14H0.999756V16H3.72198V14ZM1.99976 15V12.2279H-0.000244141V15H1.99976ZM1.70617 12.9357L12.3867 2.2762L10.9739 0.86059L0.293346 11.5201L1.70617 12.9357Z" fill="#64748B" />
                              </svg>
                            </a>
                          </td>
                          <td>
                            <a href="#" onClick={() => { deleteRecord(product.id) }} className="text-secondary font-weight-bold text-xs" data-bs-toggle="tooltip" data-bs-title="Edit user">
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </a>
                          </td>
                          <td>
									<div style={{ minWidth: 200 }}>
										{product.price.map((price, index) => (
										<div className="row prices-box mx-0" key={index}>
											 
											<div className="col-6" >
												<p>{langs.order} {price.label}</p>
												<p>
													<strong>{ parseFloat(price.price).toFixed(3) } </strong>
													<small>{langs.symbol}</small> 
												</p>
											</div>
											<div className="col-6 text-end">
											<a href="#" 
											onClick={() => { 
												if(product.status.name == "publish")
													check(price.id, product.id, product, price.type) 
											}} 
											className={`btn ${
												product.status.name == "publish" ? "btn-dark" : "btn-danger"
											}
											`}>{
												product.status.name == "publish" ? <> {langs.reserve} </> : product.status.label
											}
											</a>
											</div>
										</div>
										))}
									</div>
						  
						  
						  
                          </td>

                        </tr>

                      ))}

                    </tbody>
                  </table>
                </div>

                <div className="border-top py-3 px-3 d-flex align-items-center">
                  <p className="font-weight-semibold mb-0 text-dark text-sm">{langs.page} {products.from} {langs.of} {products.total}</p>
                  <div className="ms-auto">
                    {products.next_page_url ? <button className="btn btn-sm btn-white mb-0">{langs.next_page}</button> : null}
                    {products.prev_page_url ? <button className="btn btn-sm btn-white mb-0">{langs.previous_page}</button> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
	  
	  </DashboardLayout>
    </Layout>




  );
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);